<template>

    <div class="create-team">

        <transition name="fade">
            <div v-if="currentFormState === formState.CREATE_INPUT || currentFormState === formState.TRY_SUBMIT">
                <b-form @submit.stop.prevent="_onCreateTeamBtnClick" novalidate>

                    <b-form-group>
                        <b-form-input size="lg"
                                      v-model="team.displayName"
                                      :placeholder="$t('PlaceholderDisplayName')"
                                      :state="_calculateInputState($v.team.displayName)"
                                      tabindex="1"></b-form-input>
                        <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.team.displayName.required">{{ $t('FieldRequiredSimple') }}</span>
                            <span class="d-block" v-if="!$v.team.displayName.minLength">{{ $t('MinimumFieldLengthSimple', [2]) }}</span>
                        </b-form-invalid-feedback>
                    </b-form-group>


                    <div class="d-flex justify-content-end">
                        <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="4">
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button type="submit" variant="primary"
                                  :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="5">{{
                            $t('AddTeam') }}
                        </b-button>
                    </div>
                </b-form>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.CREATING_REMOTE" class="text-center">
                <b-spinner variant="dark" class="my-5"></b-spinner>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.REMOTE_ERROR">
                <p class="mb-4 text-danger">{{ errorMsg}}</p>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </transition>

    </div>

</template>

<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import i18n from '@/__plugins/i18n';
    import TeamModel from "@/team/_model/TeamModel";
    import languageManager from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import teamController from "@/team/_controller/TeamController";

    // validation
    import {required, minLength, email} from 'vuelidate/lib/validators';
    import {ApiResponse} from "@/_controller/ApiManager";
    import {ICreateResultDto} from "@/entity/_model/entity.dto";

    enum FormState
    {
        CREATE_INPUT,
        TRY_SUBMIT,
        CREATING_REMOTE,
        REMOTE_CREATED,
        REMOTE_ERROR
    }

    @Component({
        validations: {
            team: {
                displayName: {
                    required,
                    minLength: minLength(2)
                }
            }
        }
    })
    export default class CreateTeamBox extends Vue
    {



        //---------------------------------
        // Validations
        //---------------------------------

        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------

        private team:TeamModel = new TeamModel();

        private formState:typeof FormState = FormState;

        private currentFormState:FormState = FormState.CREATE_INPUT;

        private errorMsg:string = "";

        //---------------------------------
        // Vue Computed properties
        //---------------------------------


        get availableLanguages():string[]
        {
            return languageManager.availableLangCodes;
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        mounted()
        {
            this.team = new TeamModel();
            this.team.displayName = "";
            this.team.parentTeamID = AppUserModel.getInstance().teamID;
            this.team.availableLanguages = [...languageManager.availableLangCodes];
            this.currentFormState = FormState.CREATE_INPUT;
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private _calculateInputState(inputValidator:any):any
        {
            return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
        }

        private async _onCreateTeamBtnClick(p_e:Event)
        {

            this.currentFormState = FormState.TRY_SUBMIT;

            if (!this.$v.$invalid)
            {
                this.currentFormState = FormState.CREATING_REMOTE;

                const createResponse:ApiResponse<ICreateResultDto> = await teamController.createTeam(this.team);

                if (createResponse.hasSucceeded)
                {
                    this.$emit('onFinish', this.team);
                }
                else
                {
                    this.errorMsg = createResponse.error!.code === 409 ? this.$t('TeamCreationFailedEmailExists') as string : this.$t('TeamCreationFailed') as string;
                    this.currentFormState = FormState.REMOTE_ERROR;
                }
            }
        }

        private async _onCancelBtnClick(p_e:Event)
        {
            this.$emit('onFinish', null);
        }

    }
</script>
