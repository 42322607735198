<template>

  <div class="create-asset-folder">

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATE_INPUT || currentFormState === formState.TRY_SUBMIT">
        <b-form @submit.stop.prevent="_onCreateSlideBtnClick" novalidate>

          <b-form-group>
            <b-form-input size="lg"
                          v-model="slideName"
                          :placeholder="$t('PlaceholderSlideName')"
                          :state="_calculateInputState($v.slideName)"
                          tabindex="1"></b-form-input>
            <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.slideName.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block" v-if="!$v.slideName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-form-select v-model="construct" :options="availableConstructs"></b-form-select>
          </b-form-group>

          <b-input-group v-if="showIdentifier" :prepend="`${projectIdentifier}-`" class="mb-3">
            <b-form-input v-model="identifierInput"
                          placeholder="identifier"></b-form-input>
          </b-input-group>

          <div class="d-flex justify-content-end">
            <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="4">
              {{ $t('Cancel') }}
            </b-button>
            <b-button type="submit" variant="primary"
                      :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="5">{{
                $t('CreateSlide')
              }}
            </b-button>
          </div>

        </b-form>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATING_REMOTE" class="text-center">
        <b-spinner variant="dark" class="my-5"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="mb-4 text-danger">{{ $t('SlideCreationFailed') }}</p>
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
          <button class="btn btn-primary" @click="_onCreateSlideBtnClick">{{ $t('Retry') }}</button>
        </div>
      </div>
    </transition>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import slideListController from "@/slide/_controller/SlideListController";
import SlideModel from "@/slide/_model/SlideModel";
import {email, minLength, required} from "vuelidate/lib/validators";
import {RIGHTS} from "@/team/_model/role.constants";

enum FormState {

    CREATE_INPUT,
    TRY_SUBMIT,
    CREATING_REMOTE,
    REMOTE_CREATED,
    REMOTE_ERROR
}

@Component({
    validations: {
        slideName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class CreateSlideBox extends Vue {

    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() public boxId!: string;

    //---------------------------------
    // Vue Component data
    //---------------------------------

    private slide: SlideModel = new SlideModel();

    private formState: typeof FormState = FormState;

    private currentFormState: FormState = FormState.CREATE_INPUT;

    private construct: string = AppUserModel.getInstance().project.constructs[0];

    private showIdentifier: boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.HAS_DEVELOPER_ACCESS.identifier) >= 0;

    private projectIdentifier: string = AppUserModel.getInstance().project.identifier;

    private identifierInput: string = "";
    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get slideName() {
        return languageManager.getTranslationForValue<string>(this.slide.name, AppUserModel.getInstance().langCode)
    }

    set slideName(value: string) {
        //this.slide.name[AppUserModel.getInstance().langCode] = value;
        Vue.set(this.slide.name, AppUserModel.getInstance().langCode, value);
    }

    get availableConstructs(): string[] {
        return AppUserModel.getInstance().project.constructs;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private async _onCreateSlideBtnClick(p_e: Event) {
        this.currentFormState = FormState.TRY_SUBMIT;

        if (!this.$v.$invalid) {
            this.currentFormState = FormState.CREATING_REMOTE;
            this.slide.originalConstruct = this.construct;
            if (this.identifierInput.length > 0) {
                this.slide.identifier = this.projectIdentifier + "-" + this.identifierInput;
            }
            const createSucceeded: boolean = await slideListController.createEntity(this.slide);
            if (createSucceeded) {
                this.$emit('onFinish', this.slide);
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
            }
        }
    }

    private async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

}
</script>
