import {IResultDto} from "@/entity/_model/entity.dto";

export const SUPPORT_EMAIL = "customersuccess@audience-advantage.com";

export enum EventBusActions
{
    ASK_SHOW_ENTITY_ACTIONS = "ASK_SHOW_ENTITY_ACTIONS",
    ASK_PRESENT_CONTENT = "ASK_PRESENT_CONTENT",
    ASK_PRESENT_PLAYLIST = "ASK_PRESENT_PLAYLIST",
    ASK_PREPARE_CONTENT = "ASK_PREPARE_CONTENT",
    ASK_TRAIN_CONTENT = "ASK_TRAIN_CONTENT",
    ASK_EDIT_ENTITY = "ASK_EDIT_ENTITY",
    ASK_CREATE_CONTENT = "ASK_CREATE_CONTENT",
    ASK_CREATE_AUDIENCE = "ASK_CREATE_AUDIENCE",
    ASK_SHARE_CONTENT_WITH_AUDIENCE = "ASK_SHARE_CONTENT_WITH_AUDIENCE",
    ASK_SHARE_CONTENT_WITH_AUDIENCES = "ASK_SHARE_CONTENT_WITH_AUDIENCES",
    ASK_SHARE_FOLDER_WITH_AUDIENCE = "ASK_SHARE_FOLDER_WITH_AUDIENCE",
    ASK_SHARE_FOLDER_WITH_AUDIENCES = "ASK_SHARE_FOLDER_WITH_AUDIENCES",
    ASK_SHARE_FOLDER_WITH_TEAMS = "ASK_SHARE_FOLDER_WITH_TEAMS",
    ASK_SHARE_ENTITY_WITH_TEAMS = "ASK_SHARE_ENTITY_WITH_TEAMS",
    ASK_DELETE_ENTITY = "ASK_DELETE_ENTITY",
    ASK_COPY_ENTITY = "ASK_COPY_ENTITY",
    ASK_CREATE_EDIT_CONTENT_FOLDER = "ASK_CREATE_EDIT_CONTENT_FOLDER",
    ASK_SHOW_ENTITY_INFO = "ASK_SHOW_ENTITY_INFO",
    ASK_SHOW_ENTITY_TRANSLATION = "ASK_SHOW_ENTITY_TRANSLATION",
    ASK_UPDATE = "ASK_UPDATE",
    ASK_SHOW_FOLDER_INFO = "ASK_SHOW_FOLDER_INFO",
    ASK_DELETE_FOLDER = "ASK_DELETE_FOLDER",
    ASK_DELETE_PLAYLIST = "ASK_DELETE_PLAYLIST",
    ASK_SHARE_PLAYLIST_WITH_AUDIENCE = "ASK_SHARE_PLAYLIST_WITH_AUDIENCE",
    ASK_SHARE_PLAYLIST_WITH_AUDIENCES = "ASK_SHARE_PLAYLIST_WITH_AUDIENCES",
    ASK_CREATE_EDIT_PLAYLIST = "ASK_CREATE_EDIT_PLAYLIST",
    CREATE_PLAYLIST_FINISHED = "CREATE_PLAYLIST_FINISHED",
}

export enum LocalStorageKey
{
    AUDIENCES_META = "AUDIENCES_META",
    COMPANIES_META = "COMPANIES_META",
    CONTENTS_META = "CONTENTS_META",
    ASSET_FOLDERS_META = "ASSET_FOLDERS_META",
    SLIDES_META = "SLIDES_META",
    DATA_PROVIDERS_META = "DATA_PROVIDERS_META",
    AUDIENCE_SESSIONS = "AUDIENCE_SESSIONS",
    AUDIENCE_SESSION_EVENTS = "AUDIENCE_SESSION_EVENTS",
    ERROR_REPORTS = "ERROR_REPORTS",
    DOMAIN_INFO = "DOMAIN_INFO",
    OFFLINE_AVAILABILITY = "OFFLINE_AVAILABILITY",
    USER_SESSION = "USER_SESSION",
    PWA_INSTALLED = "PWA_INSTALLED",
    AUDIENCE_LIST_COLLAPSED = "AUDIENCE_LIST_COLLAPSED",
    PLAYLIST_COLLAPSED = "PLAYLIST_COLLAPSED",
    GROUP_BY_COMPANY = "GROUP_BY_COMPANY",
    DEEPLINK_PARAMS = "DEEPLINK_PARAMS",
    INTRODUCTION_BOX_SHOWN = "INTRODUCTION_BOX_SHOWN",
    HINTS = "HINTS",
    VERSION = "VERSION",
    GAMIFICATION_LEVEL_INDEX = "GAMIFICATION_LEVEL_INDEX",
    GAMIFICATION_STATUS_INDEX = "GAMIFICATION_STATUS_INDEX",
    GAMIFICATION_INTRODUCTION_BOX_SHOWN = "GAMIFICATION_INTRODUCTION_BOX_SHOWN",
    ROAD_MODE = "ROAD_MODE",
    LIKED_CONTENT = "PWA_LIKED_CONTENT",
}

export enum DtoType
{
    BODY = "BODY",
    META = "META",
    OUTLINE = "OUTLINE",
    CONFIG = "CONFIG"
}

export enum StorageItemType
{
    DATA = "DATA",
    FILEGROUP = "FILEGROUP"
}

export enum StorageScope
{
    GLOBAL = "GLOBAL",
    PROJECT = "PROJECT",
    APP = "APP"
}

export interface IFileDto
{
    path:string;
    fileName:string;
    lastModified:Date;
    size:number;
    hash:string;
}

export interface IUploadFileResultDto extends IResultDto
{
    mainFile:IFileDto;
    previewFile?:IFileDto;
}

export enum Runtime
{
    BROWSER = "BROWSER",
    IOS = "IOS",
    ANDROID = "ANDROID",
    UWP = "UWP"
}

export enum SortingType
{
    NAME_AZ = "NAME_AZ",
    NAME_ZA = "NAME_ZA",
    NEWEST_FIRST = "NEWEST_FIRST",
    OLDEST_FIRST = "OLDEST_FIRST"
}

export enum ServiceWorkerEvents
{
    EVENT_SERVICEWORKER_INIT_FINISHED = "EVENT_SERVICEWORKER_INIT_FINISHED",
    EVENT_SERVICEWORKER_UPDATE_WAIT_FINISHED = "EVENT_SERVICEWORKER_UPDATE_WAIT_FINISHED"
}

export enum ServiceWorkerState
{
    UNKNOWN = "UNKNOWN",
    ACTIVATED = "ACTIVATED",
    ERROR = "ERROR"
}

export enum ExternalUrls
{
    YOUTUBE_CHANNEL_EXPLORE = "https://www.youtube.com/playlist?list=PLwBliZPYGhEv1qp5f6aFB5PKm7_3tJMf9",
    YOUTUBE_CHANNEL_EXPLORE_PLUS = "https://youtube.com/playlist?list=PLwBliZPYGhEvdoCrDVWoe6vlSWXwn3Rel",
    HELP = "https://help.audience-advantage.com"
}

export enum TranslationStatus
{
    IDLE = "IDLE",
    AUTO_TRANSLATION_PENDING = "AUTO_TRANSLATION_PENDING",
    AUTO_TRANSLATION_ERROR = "AUTO_TRANSLATION_ERROR",
    AUTO_TRANSLATED = "AUTO_TRANSLATED",
    AUTO_TRANSLATED_AND_CORRECTED = "AUTO_TRANSLATED_AND_CORRECTED",
    AUTO_TRANSLATED_AND_REVIEW_NEEDED = "AUTO_TRANSLATED_AND_REVIEW_NEEDED",
    IMPORTED = "IMPORTED"
}
