<template>
  <div class="base-page share-page">

    <transition name="fade">
      <div v-if="currentSharePageState === sharePageState.LOADING"
           class="empty-loader">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentSharePageState === sharePageState.IDLE"
           class="empty-message">
        {{ $t('DashboardPlaceholderMessage') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentSharePageState === sharePageState.ERROR_LOADING"
           class="error-message">
        {{ $t('ErrorConnectionRequired') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentSharePageState === sharePageState.ERROR_LOADING" class="placeholder-text text-danger">
        {{ $t('ErrorConnectionRequired') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentSharePageState === sharePageState.LOADED" class="share-page__wrapper">
        <ShareOverview :selState="selState"></ShareOverview>
        <ShareDetail :selState="selState"></ShareDetail>
      </div>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import contentListController from "@/content/_controller/ContentListController";
import audienceController from "@/audience/_controller/AudienceController";

import SelectionState from "@/__libs/_model/SelectionState";
import {IEntityAudienceShareOutlineDto} from "@/entity/_model/entity.dto";
import ShareOverviewItemRenderer from "@/audience/share/_view/ShareOverviewItemRenderer.vue";
import ShareDetail from "@/audience/share/_view/ShareDetail.vue";
import AppModel from "@/_model/AppModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import PreviewVisitorPageBox from "@/audience/_view/PreviewVisitorPageBox.vue";
import {IAudienceFileDto} from "@/audience/_model/audience.dto";
import ShareOverview from "@/audience/share/_view/ShareOverview.vue";


enum SharePageState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED
}

@Component({
    components: {ShareOverview, ShareOverviewItemRenderer, ShareDetail, PreviewVisitorPageBox}
})
export default class SharePage extends Vue {
    private sharePageState: typeof SharePageState = SharePageState;
    private currentSharePageState: SharePageState = SharePageState.IDLE;

    private selState: SelectionState<IEntityAudienceShareOutlineDto | IAudienceFileDto> = new SelectionState<IEntityAudienceShareOutlineDto | IAudienceFileDto>();

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    public mounted() {
    }

    @Watch('audience', {immediate: true, deep: false})
    private _onActiveAudienceChange(newAudience: AudienceModel | null, oldAudience: AudienceModel | null) {
        this._loadShares();
    }

    private async _loadShares() {
        this.selState.selected = null;
        if (this.audience) {
            this.currentSharePageState = SharePageState.LOADING;
            const hasSucceeded: boolean = await contentListController.fetchAllContentSharesForAudience(this.audience);
            if (hasSucceeded) {
                await audienceController.fetchConfig(this.audience);
                await audienceController.fetchFiles(this.audience);
                this.currentSharePageState = SharePageState.LOADED;
            } else {
                this.currentSharePageState = SharePageState.ERROR_LOADING;
            }
        } else {
            this.currentSharePageState = SharePageState.IDLE;
        }
    }
}
</script>
