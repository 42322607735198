<template>
  <div class="slide-detail">

    <div class="box box--full-height box--scrollable slide-detail-primary" :id="hintIds.SLIDES2_EDITING">

      <transition name="fade">
        <div v-if="!selState.selected"
             class="empty-message">
          {{ $t('PleaseSelectSlide') }}
        </div>
      </transition>


      <transition name="fade">
        <div v-if="selState.selected" :key="`${selState.selected.ID}_primary`" class="box-wrapper">

          <h4 class="title mt-1 mb-4">{{ $t('slideName') }}</h4>

          <div class="responsive responsive--16-9 mb-4">
            <div class="responsive__wrapper">
              <PresentationPlayer
                  engineMode="SINGLE_SLIDE"
                  :slideID="selState.selected.ID"/>
            </div>
          </div>

          <EntityActionBox :entity="slide" edit-allowed="true" class="w-100"></EntityActionBox>

        </div>
      </transition>

    </div>

    <div class="box box--full-height box--scrollable slide-detail-secondary">

      <transition name="fade">
        <div v-if="!selState.selected"
             class="empty-message">
          {{ $t('PleaseSelectSlide') }}
        </div>
      </transition>

      <transition name="fade">
        <div v-if="selState.selected" :key="`${selState.selected.ID}_secondary`" class="box-wrapper">

          <h4 class="title mt-1 mb-4">{{ $t('UserDetails') }}</h4>

          <table class="table table-sm table-striped table-borderless mb-5">
            <tbody>
            <tr>
              <th scope="row">{{ $t('CreatedBy') }}</th>
              <td>{{ slide.createdByUserName }} ({{ slide.createdByTeamName }})</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('CreatedDate') }}</th>
              <td>{{ $d(new Date(slide.createdDate), 'short') }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('LastUpdateOn') }}</th>
              <td>{{ $d(new Date(slide.updatedDate), 'short') }}</td>
            </tr>
            </tbody>
          </table>

          <h5 class="title mb-4">{{ $t('SlideUsage') }}</h5>

          <div v-if="usedInPresentations.length === 0" class="empty-message">{{ $t('SlideNotYetUsed') }}</div>

          <div v-if="usedInPresentations.length > 0" class="sub-box slide-detail__box">
            <h6 class="d-flex"><i class="fas fa-clipboard-list"></i>{{ $t('SlideUsedInPresentations') }}</h6>
            <ul>
              <li v-for="pres in usedInPresentations" :key="pres.ID">
                {{ getSlideName(pres) }} <i>{{ getSlideInternalName(pres) }}</i>
              </li>
            </ul>
          </div>
        </div>
      </transition>

    </div>

    <b-popover custom-class="hint-popover" :target="hintIds.SLIDES2_EDITING"
               placement="bottom"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.SLIDES2_EDITING"></Hint>
    </b-popover>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import SlideModel from "@/slide/_model/SlideModel";
import slideController from "@/slide/_controller/SlideController";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AudienceModel from "@/audience/_model/AudienceModel";
import PresentationPlayer from "@/presentation/player/_view/PresentationPlayer.vue";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintIds} from "@/help/hint/_model/hint.constants";

@Component({
    components: {Hint, EntityActionBox, PresentationPlayer}
})
export default class SlideDetail extends Vue {

    @Prop() private selState!: SlideSelectionState;

    private hintIds: typeof HintIds = HintIds;

    get usedInPresentations() {
        if (this.slide) {
            return this.slide.usedInPresentations;
        } else {
            return [];
        }

    }

    get slide(): SlideModel | null {
        return this.selState.selected;
    }

    get slideName() {
        return languageManager.getTranslationForValue<string>(this.slide!.name, AppUserModel.getInstance().langCode)
    }

    public mounted() {
    }

    public getSlideName(p_slide: any) {
        return languageManager.getTranslationForValue<string>(p_slide.name, AppUserModel.getInstance().langCode)
    }

    public getSlideInternalName(p_slide: any) {
        const trans: string = languageManager.getTranslationForValue<string>(p_slide.internalName, AppUserModel.getInstance().langCode);
        if (!trans) {
            return "";
        } else {
            return "(" + trans + ")";
        }
    }

    @Watch('slide', {immediate: true, deep: false})
    private _onActiveSlideChange(newAudience: AudienceModel | null, oldAudience: AudienceModel | null) {
        if (this.slide) {
            slideController.fetchSlideUsage(this.slide);
        }

    }
}
</script>
