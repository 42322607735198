import {IEntityBodyDto} from "@/entity/_model/entity.dto";
import SyncItemProcess from "@/sync/_controller/process/SyncItemProcess";
import {IFileGroupCfgDto, ISyncFileDto, ISyncItemDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import Util from "@/__libs/utility/Util";
import {SyncFileRetrieveStatus} from "@/sync/_model/sync.constants";
import syncController from "@/sync/_controller/SyncController";
import {IAudienceBodyDto} from "@/audience/_model/audience.dto";
import {IFileOutlineDto} from "@/asset_folder/_model/asset_folder.dto";

class SyncFileGroupProcess extends SyncItemProcess
{

    //---------------------------------
    // Properties
    //---------------------------------



    //---------------------------------
    // Controller Methods
    //---------------------------------


    protected _resolveItemFilePath(p_syncItem:ISyncItemDto):string
    {
        return `/${this._storagePath}/${p_syncItem.identifier}/cfg.json`

    }



    //could be overridden if needed
    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {

        const cfg:IFileGroupCfgDto = p_itemFile.body;
        const fileGroupPath:string = `/${this._storagePath}/${p_itemFile.identifier}`;

        for (let i = 0; i < cfg.files.length; i++)
        {
            const fileOutline:IFileOutlineDto = cfg.files[i];

            const syncFile:ISyncFileDto = {
                remoteVersion : fileOutline.version,
                path          : `${fileGroupPath}/${fileOutline.path}`,
                storageScope  : this._storageScope,
                retrieveStatus: SyncFileRetrieveStatus.IDLE,
                nrOfAttempts  : 0
            };

            syncController.fileGroupFileProcess.addFileGroupFile(syncFile);
        }
    }

    //---------------------------------
    // Private Methods
    //---------------------------------



}

//Singleton export
export default SyncFileGroupProcess;
