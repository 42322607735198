<template>
    <div class="edit-content-file-box">

        <transition name="fade">
            <div :key="contentApp.ID">

                <h4 class="mb-3">{{ contentAppName }}</h4>

                <!--                <MorePopup :id="`content-file-${contentApp.ID}`" placement="right">-->
                <!--                    <EntityActionBox :entity="contentApp" :editAllowed="false"/>-->
                <!--                </MorePopup>-->

                <div class="aa-container">
                    <div class="row">

                        <div class="col-4">
                            <AAImage class="mb-3" :imageUrl="posterUrl"
                                     v-on:image-loaded="previewLoaded = true"></AAImage>
                            <button v-if="contentApp.fileType !== fileType.IMAGE" class="btn btn-primary btn-block"
                                    @click="_onChoosePosterBtnClick">
                                {{ $t('ChoosePoster') }}
                            </button>
                        </div>

                        <div class="col-8">

                            <transition name="fade">
                                <div :key="contentApp.ID">

                                    <b-form-group>
                                        <b-form-input v-model="validationContentAppName"
                                                      :placeholder="$t('Name')"
                                                      :state="_calculateInputState($v.validationContentAppName)"
                                                      @input="contentAppName = _onFormValidationInput($v.validationContentAppName, contentAppName)"
                                                      tabindex="1"></b-form-input>
                                        <b-form-invalid-feedback>
                                            <span class="d-block" v-if="!$v.validationContentAppName.required">{{ $t('FieldRequiredSimple') }}</span>
                                            <span class="d-block" v-if="!$v.validationContentAppName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <b-form-input  v-model="contentAppPortalName"
                                                     :placeholder="$t('PortalName')"
                                                     @input="_onFormInput"
                                                     tabindex="2" class="mb-3"></b-form-input >

                                    <b-form-textarea v-model="contentAppNotes"
                                                     :placeholder="$t('Notes')"
                                                     @input="_onFormInput"
                                                     rows="5"
                                                     tabindex="3" class="mb-3"></b-form-textarea>

                                    <label class="btn btn-outline-secondary mb-0 mr-3"
                                           v-if="contentApp.uploadStatus === uploadStatus.IDLE">
                                        {{ $t('AssetManagementReplaceFile') }} <input type="file" ref="replaceFileBtn"
                                                                                      v-on:change="_onReplaceFileBtnClick()"
                                                                                      hidden>
                                    </label>

                                    <button v-if="showDataPicker"
                                            class="btn btn-primary mb-0" @click="_onOpenDtpPickerBtnClick">
                                        {{ $t('ChooseDataForThisApp') }}
                                    </button>

                                    <!--                        <button  class="btn btn-outline-secondary btn-block" @click="_onDeleteFileBtnClick">-->
                                    <!--                            {{ $t('DashboardShowDeleteButton') }}-->
                                    <!--                        </button>-->
                                </div>
                            </transition>

                            <transition name="fade">
                                <b-spinner v-if="contentApp.uploadStatus === uploadStatus.UPLOADING"></b-spinner>
                            </transition>

                            <transition name="fade">
                                <b-spinner type="grow"
                                           v-if="contentApp.uploadStatus === uploadStatus.QUEUED"></b-spinner>
                            </transition>

                        </div>

                    </div>
                </div>

            </div>
        </transition>

        <b-modal id="poster-picker-box" size="xl" :title="$t('PickPosterOrUploadNewOne')" hide-footer>
            <AssetFoldersPicker :selectedFileUri="posterUrl"
                                :allowedTargetType="posterAllowedTargetType"
                                :allowedFileTypes="avatarAllowedFileTypes"
                                v-on:onAssetFilePicked="_onPosterFilePicked"></AssetFoldersPicker>
        </b-modal>

        <b-modal id="dataprovider-picker-box" size="xl" :title="$t('PickDataOrCreateNewOne')" hide-footer>
            <DataProvidersPicker
                    :selectedDtpUri="contentApp.dataProviderURI"
                    :allowedSchema="contentApp.dataSchemaURI"
                    v-on:onDtpPicked="_onDtpPicked"></DataProvidersPicker>
        </b-modal>

    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";
    import languageManager from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {UploadStatus} from "@/entity/_model/entity.constants";
    import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
    import fileManager from "@/_controller/FileManager";
    import {minLength, required} from "vuelidate/lib/validators";
    import AAImage from "@/_view/components/AAImage.vue";
    import ContentAppModel from "@/content_app/_model/ContentAppModel";
    import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
    import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
    import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
    import MorePopup from "@/_view/components/MorePopup.vue";
    import DataProvidersPicker from "@/data_tool/data_provider/_view/DataProvidersPicker.vue";
    import {IEngineMessageDto} from "@/presentation/player/_model/player.dto";
    import {EngineMessageType} from "@/presentation/player/_model/player.constants";
    import {RIGHTS} from "@/team/_model/role.constants";


    @Component({
        components : {
            AAImage,
            AssetFoldersPicker,
            MorePopup,
            EntityActionBox,
            DataProvidersPicker
        },
        validations: {
            validationContentAppName: {
                required,
                minLength: minLength(3)
            }
        }
    })
    export default class EditContentAppBox extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------


        @Prop() private contentApp!:ContentAppModel;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        private validationContentAppName:string = '';

        private uploadStatus:typeof UploadStatus = UploadStatus;

        private fileType:typeof FileType = FileType;

        private previewLoaded:boolean = false;

        public posterAllowedTargetType:FileTargetType = FileTargetType.PRESENTATION_POSTER;
        public avatarAllowedFileTypes:FileType[] = [FileType.IMAGE];
        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get contentAppName()
        {
            return languageManager.getTranslationForValue<string>(this.contentApp.name, AppUserModel.getInstance().langCode)
        }

        set contentAppName(value:string)
        {
            this.contentApp.name[AppUserModel.getInstance().langCode] = value;
        }

        get contentAppPortalName()
        {
            if(this.contentApp.portalName)
            {
                return languageManager.getTranslationForValue<string>(this.contentApp.portalName, AppUserModel.getInstance().langCode)

            }
            return "";
        }

        set contentAppPortalName(value:string)
        {
            if(!this.contentApp.portalName)
            {
                this.contentApp.portalName = {};
            }
            this.contentApp.portalName[AppUserModel.getInstance().langCode] = value;
        }

        get contentAppNotes()
        {
            return languageManager.getTranslationForValue<string>(this.contentApp.notes, AppUserModel.getInstance().langCode)
        }

        set contentAppNotes(value:string)
        {
            this.contentApp.notes[AppUserModel.getInstance().langCode] = value;
        }


        get posterUrl()
        {
            const posterUri:string = languageManager.getTranslationForValue(
                this.contentApp.poster,
                AppUserModel.getInstance().langCode
            );
            return fileManager.getFileUrl(posterUri);
        }

        get showDataPicker():boolean
        {
            if (this.contentApp.dataSchemaURI && this.contentApp.dataSchemaURI.length > 0)
            {
                return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_DATA_PROVIDERS.identifier) >= 0;
            }
            return false;
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            this.validationContentAppName = this.contentAppName;
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private _calculateInputState(inputValidator:any):any
        {
            return inputValidator.$invalid ? false : null;
        }

        private _onFormValidationInput(inputValidator:any, value:any):void
        {
            if (!inputValidator.$invalid && this.contentAppName)
            {
                value = inputValidator.$model;
                this._onFormInput(null);
            }
            return value;
        }

        private _onFormInput(p_e:Event | null)
        {
            if (this.contentApp)
            {
                this.contentApp.hasChanges = true;
            }
        }



        private async _onChoosePosterBtnClick(p_e:Event)
        {
            this.$bvModal.show('poster-picker-box');
        }



        private _onPosterFilePicked(p_assetFile:AssetFileModel)
        {
            this.$bvModal.hide('poster-picker-box');
            if (p_assetFile && this.contentApp)
            {
                this.contentApp.poster[AppUserModel.getInstance().langCode] = p_assetFile.fileURI;
                this.contentApp.hasChanges = true;
            }
        }

        private async _onOpenDtpPickerBtnClick(p_e:Event)
        {
            this.$bvModal.show('dataprovider-picker-box');
        }

        private _onDtpPicked(p_dataProviderID:string)
        {
            this.$bvModal.hide('dataprovider-picker-box');
            if (p_dataProviderID && this.contentApp)
            {
                this.contentApp.dataProviderURI = "dataProvider://" + p_dataProviderID;
                this.contentApp.hasChanges = true;
            }
        }
    }
</script>
