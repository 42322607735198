<template>
  <div class="main-sub-page settings-page">

    <div class="sub-menu-bar">
      <div class="sub-menu">
        <router-link :to="{ name: routingIdentifiers.APP_USER }" class="sub-menu-item">
          <i class="fas fa-user"></i>
          <h6 v-html="$t('MainMenuProfileSettings')"></h6>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.GAMIFICATION }" class="sub-menu-item"
                     v-if="showGamification">
          <i class="fas fa-trophy"></i>
          <h6 v-html="$t('MainMenuGamification')"></h6>
        </router-link>
        <router-link
            :to="{ name: routingIdentifiers.TARGET_TRACKING, params: { pageIdentifier: targetTrackingPageConfig.route } }"
            class="sub-menu-item" v-if="showTargetTracking">
          <i :class="targetTrackingPageIcon"></i>
          <h6 v-html="targetTrackingPageName"></h6>
        </router-link>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CreateTeamUserBox from "@/team/_view/CreateTeamUserBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RoutingIdentifier} from "@/router";
import roleController from "@/team/_controller/RoleController";
import {RIGHTS} from "@/team/_model/role.constants";
import {IProjectCustomPageDto} from "@/project/_model/project.dto";
import languageManager from "@/__libs/language_manager/LanguageManager";

@Component({
    components: {}
})
export default class SettingsPage extends Vue {

    private routingIdentifiers = RoutingIdentifier;
    public appUser: AppUserModel = AppUserModel.getInstance();

    get showGamification(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_GAMIFICATION.identifier) >= 0;
    }

    get targetTrackingPageConfig(): IProjectCustomPageDto | null {
        if (AppUserModel.getInstance().project.config.customPages) {
            return AppUserModel.getInstance().project.config.customPages.find((page: IProjectCustomPageDto) => page.route === this.routingIdentifiers.TARGET_TRACKING) as IProjectCustomPageDto;
        }
        return null;
    }

    get targetTrackingPageName() {
        if (this.targetTrackingPageConfig) {
            return languageManager.getTranslationForValue<string>(this.targetTrackingPageConfig.name, AppUserModel.getInstance().langCode)
        }
        return null;
    }

    get targetTrackingPageIcon() {
        if (this.targetTrackingPageConfig) {
            return this.targetTrackingPageConfig.icon
        }
        return null;
    }

    get showTargetTracking(): boolean {
        if (this.targetTrackingPageConfig) {
            if (this.targetTrackingPageConfig.availableToTeams) {
                if (this.targetTrackingPageConfig.availableToTeams.indexOf(AppUserModel.getInstance().teamID) < 0) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

}
</script>
