<template>
  <div class="box box--full-height box--scrollable audience-summary">

    <transition name="fade">
      <div v-if="!audience"
           class="empty-message">
        {{ $t('DashboardPlaceholderMessage') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="audience" :key="`${audience.ID}`" class="audience-summary__content">

        <AAProfilePicture :name="audience.displayName"
                          :imageUri="audience.avatarFileUri"
                          :editable="true"
                          :huge="true"
                          class="mb-4"
                          v-on:click.native="_onOpenAssetPickerBtnClick"></AAProfilePicture>

        <h2 class="title">{{ audience.displayName }}</h2>

        <AAImage v-if="audienceIsImported" :imageUrl="crmLogoPath" class="audience-summary__crm mt-3" />

        <EntityActionBox :entity="audience" :editAllowed="false" class="mt-5 mb-3"></EntityActionBox>

        <div class="audience-summary__privacy">{{ $t('ConsentGivenOn') }}: <strong>{{consentDate}}</strong></div>

      </div>
    </transition>

    <b-modal id="audience-asset-picker-box" size="xl" :title="$t('PickAudienceAvatarOrUploadNewOne')" hide-footer>
      <AssetFoldersPicker v-if="audience"
                          :selectedFileUri="audience.avatarFileUri"
                          :allowedTargetType="avatarAllowedTargetType"
                          :allowedFileTypes="avatarAllowedFileTypes"
                          v-on:onAssetFilePicked="_onAssetFilePicked"></AssetFoldersPicker>
    </b-modal>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Avatar from "vue-avatar";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";

import audienceController from "@/audience/_controller/AudienceController";
import fileManager from "@/_controller/FileManager";
import languageManager from "@/__libs/language_manager/LanguageManager";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import {email, minLength, required} from "vuelidate/lib/validators";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import {RoutingIdentifier} from "@/router";
import DataProviderEditor from "@/data_tool/data_provider/_view/DataProviderEditor.vue";
import CompanyModel from "@/company/_model/CompanyModel";
import CompanyListModel from "@/company/_model/CompanyListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import AAImage from "@/_view/components/AAImage.vue";

@Component({
  components: {
    AAImage,
    AAProfilePicture, EntityActionBox, AssetFoldersPicker
  },
})
export default class AudienceSummary extends Vue {

  public avatarAllowedTargetType: FileTargetType = FileTargetType.AUDIENCE_AVATAR;
  public avatarAllowedFileTypes: FileType[] = [FileType.IMAGE];

  get audience(): AudienceModel | null {
    return AudienceListModel.getInstance().globalSelState.selected;
  }

  get audienceIsImported(): boolean {
    return this.audience!.importedFrom !== 'NONE';
  }

  get crmLogoPath() {
    return `img/crm/${this.audience!.importedFrom}.png`;
  }

  get consentDate():string {
      return this.audience!.consentDate ? this.$d(new Date(this.audience!.consentDate!), 'short') : "-";
  }

  private _onOpenAssetPickerBtnClick(p_e: Event) {
    this.$bvModal.show('audience-asset-picker-box');
  }

  private _onAssetFilePicked(p_assetFile: AssetFileModel) {
    this.$bvModal.hide('audience-asset-picker-box');
    if (p_assetFile && this.audience) {
      this.audience.avatarFileUri = p_assetFile.fileURI;
      this.audience.hasChanges = true;
    }
  }

}
</script>
