<template>
  <div class="app-user-detail box box--full-height box--scrollable" v-if="appUser">

    <h4 class="title mt-1 mb-4">{{ $t('ProfileTitle') }}</h4>

    <b-form-group>
      <b-form-input size="lg"
                    v-model="validationAppUser.displayName"
                    :placeholder="$t('PlaceholderDisplayName')"
                    :state="_calculateInputState($v.validationAppUser.displayName)"
                    @input="appUser.displayName = _onFormValidationInput($v.validationAppUser.displayName, appUser.displayName)"
                    tabindex="1"></b-form-input>
      <b-form-invalid-feedback>
                                                    <span class="d-block"
                                                          v-if="!$v.validationAppUser.displayName.required">{{
                                                        $t('FieldRequiredSimple')
                                                      }}</span>
        <span class="d-block"
              v-if="!$v.validationAppUser.displayName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.firstName"
                    :placeholder="$t('PlaceholderFirstName')"
                    @input="_onFormInput"
                    tabindex="2"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.lastName"
                    :placeholder="$t('PlaceholderLastName')" @input="_onFormInput"
                    tabindex="3"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-select v-model="appUser.langCode"
                     @change="_onChangeUserLangCode" tabindex="4">
        <option v-for="langCode in availableLanguages" :key="langCode"
                :value="langCode">{{ langCode }}
        </option>
      </b-form-select>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.telNr"
                    :placeholder="$t('PlaceholderTelNr')" @input="_onFormInput"
                    tabindex="6"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.mobileNr"
                    :placeholder="$t('PlaceholderMobileNr')" @input="_onFormInput"
                    tabindex="7"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.faxNr"
                    :placeholder="$t('PlaceholderFaxNr')" @input="_onFormInput"
                    tabindex="8"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.department"
                    :placeholder="$t('PlaceholderDepartment')" @input="_onFormInput"
                    tabindex="9"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.jobTitle"
                    :placeholder="$t('PlaceholderJobTitle')" @input="_onFormInput"
                    tabindex="10"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.address"
                    :placeholder="$t('PlaceholderAddress')" @input="_onFormInput"
                    tabindex="11"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.website"
                    :placeholder="$t('PlaceholderWebsite')" @input="_onFormInput"
                    tabindex="12"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="appUser.signature"
                    :placeholder="$t('PlaceholderSignature')" @input="_onFormInput"
                    tabindex="13"></b-form-input>
    </b-form-group>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import appUserController from "@/project/user/_controller/AppUserController";
import {minLength, required} from "vuelidate/lib/validators";


@Component({
  components: {},
  validations: {
    validationAppUser: {
      displayName: {
        required,
        minLength: minLength(3)
      }
    }
  }
})
export default class AppUserDetail extends Vue {

  public appUser: AppUserModel = AppUserModel.getInstance();
  private validationAppUser: AppUserModel = new AppUserModel();

  get availableLanguages(): string[] {
    return languageManager.availableLangCodes;
  }

  get hasChanges() {
    return this.appUser && this.appUser.hasChanges;
  }

  mounted() {
    this._createValidationAppUser();
    window.addEventListener('beforeunload', this._beforeUnload);
  }

  beforeDestroy() {
    this._saveUser();
    window.removeEventListener('beforeunload', this._beforeUnload);
  }

  private _calculateInputState(inputValidator: any): any {
    return inputValidator.$invalid ? false : null;
  }

  private _beforeUnload(e: BeforeUnloadEvent) {
    this._saveUser();
  }

  private _createValidationAppUser(): void {
    if (this.appUser) {

      this.validationAppUser = new AppUserModel();
      this.validationAppUser.displayName = this.appUser.displayName;
    }
  }

  private _onFormValidationInput(inputValidator: any, value: any): void {
    if (!inputValidator.$invalid && this.appUser) {
      value = inputValidator.$model;
      this._onFormInput(null);
    }
    console.log(value);
    return value;
  }

  private _onFormInput(p_e: Event | null) {
    this.appUser.hasChanges = true;
  }

  private _onChangeUserLangCode(p_e: Event) {
    this.appUser.hasChanges = true;
    this.$i18n.locale = this.appUser.langCode;
  }

  private _saveUser() {
    if (this.appUser.hasChanges) {
      appUserController.saveUser();
    }
  }

}
</script>

