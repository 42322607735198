<template>
  <div class="base-page track-page">

    <transition name="fade">
      <div v-if="currentState === trackPageState.LOADING"
           class="empty-loader">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === trackPageState.NO_ACTIVITIES" class="empty-message text-body">
        {{ $t('NoAnalyticsForAudience') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === trackPageState.IDLE" class="empty-message text-secondary">
        {{ $t('DashboardPlaceholderMessage') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === trackPageState.ERROR_LOADING" class="error-message">
        {{ $t('ErrorConnectionRequired') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === trackPageState.LOADED" class="track-page__wrapper">

        <ActivityOverview :selState="selState"></ActivityOverview>
        <ActivityDetail :selState="selState"></ActivityDetail>

      </div>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import audienceController from "@/audience/_controller/AudienceController";
import SelectionState from "@/__libs/_model/SelectionState";
import {IAudienceActivityDto} from "@/audience/track/_model/audience_activity.dto";
import ActivityOverviewItemRenderer from "@/audience/track/_view/ActivityOverviewItemRenderer.vue";
import ActivityDetail from "@/audience/track/_view/ActivityDetail.vue";
import ActivityOverview from "@/audience/track/_view/ActivityOverview.vue";


enum TrackPageState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED,
    NO_ACTIVITIES
}

@Component({
    components: {ActivityOverview, ActivityOverviewItemRenderer, ActivityDetail}
})
export default class TrackPage extends Vue {

    public trackPageState: typeof TrackPageState = TrackPageState;
    public currentState: TrackPageState = TrackPageState.IDLE;
    public selState: SelectionState<IAudienceActivityDto> = new SelectionState<IAudienceActivityDto>();

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    public mounted() {
    }

    @Watch('audience', {immediate: true, deep: false})
    private _onActiveAudienceChange(newAudience: AudienceModel | null, oldAudience: AudienceModel | null) {
        this._loadActivities();
    }

    private async _loadActivities() {
        this.selState.selected = null;
        if (this.audience) {
            this.currentState = TrackPageState.LOADING;
            const hasSucceeded: boolean = await audienceController.fetchAllActivities(this.audience);
            if (hasSucceeded) {
                if (this.audience.activities.length > 0) {
                    this.currentState = TrackPageState.LOADED;
                } else {
                    this.currentState = TrackPageState.NO_ACTIVITIES;
                }
            } else {
                this.currentState = TrackPageState.ERROR_LOADING;
            }
        } else {
            this.currentState = TrackPageState.IDLE;
        }
    }

}
</script>
