class Util
{
    //---------------------------------
    // Public Static Methods
    //---------------------------------

    public static delay(ms:number):Promise<void>
    {
        return new Promise(res => setTimeout(res, ms));
    }

    public static setLeadingZero(p_number:number):string
    {
        let number_str:string = p_number.toString();
        if (p_number < 10)
        {
            number_str = "0" + number_str;
        }
        return number_str;
    }



    public static timeOut(ms:number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    public static composeTemplateHtmlFromData(p_template:string, p_data:{ [index:string]:any }, p_replaceLineBreaks:boolean = false)
    {
        //replace all template params
        for (let key in p_data)
        {
            const keyParam = "{" + key + "}";
            if (p_data[key] != null && p_data[key] != undefined)
            {
                let keyValue:string = p_data[key].toString();
                if (p_replaceLineBreaks)
                {
                    //replace all line breaks
                    keyValue = keyValue.replace(/(?:\r\n|\r|\n)/g, '<br>');
                }
                p_template = p_template.split(keyParam).join(keyValue);
            }
        }
        return p_template;
    }

    public static deepCopy(p_obj:any):any
    {
        return JSON.parse(JSON.stringify(p_obj));
    }

    public static extractHostname(url:string)
    {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1)
        {
            hostname = url.split('/')[2];
        }
        else
        {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname;
    }

    public static isIOS():boolean
    {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    }

    public static calculatePercentage(first:any, second:any)
    {
        let percentage:number = Math.round((second - first) / first * 100);
        if (percentage === Number.POSITIVE_INFINITY || percentage === Number.NEGATIVE_INFINITY)
        {
            percentage = 100;
        }
        if (isNaN(percentage))
        {
            percentage = 0;
        }
        return percentage;
    }

    public static validateEmail(p_email:string):boolean
    {
        // var mailformat = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
        // return !!p_email.match(mailformat)
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(p_email);

    }

    public static getDaysInThisMonth():number
    {
        let dateToday = new Date();
        let lastDayOfMonth = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0).getDate();
        return lastDayOfMonth - dateToday.getDate();
    }

    public static getRandomColor()
    {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++)
        {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    public static excelJsIsRichValue(p_value:any)
    {
        return Boolean(p_value && Array.isArray(p_value.richText));
    }

    public static excelJsRichToString(p_rich:any)
    {
        // @ts-ignore
        return p_rich.richText.map(({text}) => text).join('');
    }

    public static dateFormat(p_date:Date, p_format:string = "SHORT", p_locale:string = "EUR"):string
    {
        if (p_date == null)
        {
            return "-";
        }
        if (typeof p_date === "string")
        {
            return p_date;
        }
        let date_str:string = p_date.toString();
        if (p_format === "SHORT")
        {
            if (p_locale === "EUR")
            {
                date_str = Util.setLeadingZero(p_date.getDate()) + "-" + Util.setLeadingZero(p_date.getMonth() + 1) + "-" + p_date.getFullYear();
            }
        }
        else if (p_format === "SQL")
        {
            date_str = p_date.getFullYear() + "-" + Util.setLeadingZero(p_date.getMonth() + 1) + "-" + Util.setLeadingZero(p_date.getDate())

        }

        return date_str;
    }

}

export default Util;
