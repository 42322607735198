<template>
  <div class="playlist-overview">

    <div class="box box--full-height">

      <transition name="fade">
        <div v-if="currentOverviewState === overviewState.PLAYLISTS" class="h-100 list-overview">
          <h5 class="title mt-1 mb-3" v-html="$t('Playlists')"></h5>
          <div class="list-overview-filters">
            <b-input-group>
              <template #prepend>
                <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
              </template>
              <b-form-input v-model="searchFilter"
                            :placeholder="$t('SearchMyPlaylist')"></b-form-input>
            </b-input-group>
            <div class="filter-button" @click="_orderPlaylistsAlphabetClick"
                 :class="{  active: orderedAlphabet }" v-b-tooltip.hover.top
                 :title="$t('ToolTipOrderAlphabet')">
              <i class="fas fa-sort-alpha-down fa-lg"></i>
            </div>
          </div>
          <b-button block variant="primary" class="list-overview-button" @click="_onCreatePlaylistBtnClick"
                    v-html="$t('AddPlaylist')"></b-button>
          <div class="list-overview-items">
            <PlaylistItemRenderer
                v-for="playlist in filteredPlaylists"
                :playlist="playlist"
                :key="playlist.playlistID"
                v-on:onClick="_onPlaylistClick"
                v-on:onClickDots="_onPlaylistDotsClick"/>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="currentOverviewState === overviewState.PLAYLIST_ENTRIES && playlistListModel.selectedPlaylist" class="h-100 list-overview">
          <h5 class="title mt-1 mb-3">{{ selectedPlaylistName }}</h5>
          <b-button @click="_onBackBtnClick" class="mb-3"><i
              class="fa-solid fa-chevron-left mr-2"></i>{{ $t('PlaylistBack') }}
          </b-button>
          <div class="list-overview-items mb-3">
            <div class="playlist-overview__dropzone" :class="{ dropAttract: isBeingDraggedOver }"
                 @dragover="_onDragOver" @dragenter="_onDragEnter" @dragleave="_onDragLeave" @drop="_onDrop">
              <div class="playlist-overview__dropzone__text"
                   v-if="playlistListModel.selectedPlaylist.entries.length === 0">{{ $t('DragDropContentHere') }}
              </div>
            </div>
            <PlaylistEntryItemRenderer
                v-for="(entry, index) in playlistListModel.selectedPlaylist.entries"
                :playlistEntry="entry"
                :key="index"
                v-on:onClick="_onPlaylistEntryClick"
                :inPlayerMode="false"
            />
          </div>
          <b-button v-if="isAudienceOnline" :disabled="!hasJoinedRoom" block variant="success"
                    @click="_onPresentContentBtnClick" class="action-btn">
            <i class="fas fa-chalkboard-teacher fa-lg"></i>{{ $t('EntityActionBoxPresentRemote') }}
          </b-button>
          <b-button block variant="success" @click="_onPresentContentBtnClick"
                    :disabled="playlistListModel.selectedPlaylist.entries.length === 0" v-else class="action-btn">
            <i class="fas fa-chalkboard-teacher fa-lg"></i>{{ $t('EntityActionBoxPresent') }}
          </b-button>
          <b-button v-if="selectedPlaylistEditable" block variant="secondary" class="mb-2 action-btn"
                    @click="_onEditPlaylistBtnClick">
            <i class="fas fa-pen-to-square fa-lg"></i>{{ $t('DashboardShowEditButton') }}
          </b-button>
        </div>
      </transition>

    </div>

    <div class="playlist-overview__toggle" @click="toggle">
      <i class="fas fa-chevron-left" v-if="!playlistOverviewOpen"></i>
      <i class="fas fa-chevron-right" v-if="playlistOverviewOpen"></i>
    </div>


  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PlaylistItemRenderer from "@/playlist/_view/PlaylistItemRenderer.vue";
import PlaylistModel from "@/playlist/_model/PlaylistModel";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import {RoutingIdentifier} from "@/router";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import CreateEditPlaylistBox from "@/playlist/_view/CreateEditPlaylistBox.vue";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import PlaylistEntryItemRenderer from "@/playlist/_view/PlaylistEntryItemRenderer.vue";
import {IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";
import ContentModel from "@/content/_model/ContentModel";
import contentFolderController from "@/content/_controller/ContentFolderController";
import playlistController from "@/playlist/_controller/PlaylistController";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import {
    CobrowseUserType
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";
import {CobrowseRoomType} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import AppModel from "@/_model/AppModel";

enum OverviewState {
    PLAYLISTS,
    PLAYLIST_ENTRIES
}


@Component({
    components: {
        PlaylistItemRenderer, CreateEditPlaylistBox, PlaylistEntryItemRenderer
    },
})
export default class PlaylistsOverview extends Vue {

    public appModel: AppModel = AppModel.getInstance();
    public playlistListModel: PlaylistListModel = PlaylistListModel.getInstance();
    public playlists: PlaylistModel[] = this.playlistListModel.playlists;
    public orderedAlphabet: boolean = false;
    public searchFilter: string = "";
    public overviewState: typeof OverviewState = OverviewState;
    public currentOverviewState: OverviewState = OverviewState.PLAYLISTS;

    public dragCount: number = 0;
    public isBeingDraggedOver: boolean = false;
    public dropFinished: boolean = false;

    public cobrowseManager: CobrowseManager = CobrowseManager.getInstance();
    public appUserModel: AppUserModel = AppUserModel.getInstance();


    get playlistOverviewOpen(): boolean {
        return this.appModel.playlistOverviewOpen;
    }

    get filteredPlaylists() {
        if (this.searchFilter.length > 1) {
            return this.playlists.filter(playlist => {
                return languageManager
                  .getTranslationForValue<string>(playlist.body.name, AppUserModel.getInstance().langCode).toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1
            });
        } else {
            return this.playlists;
        }
    }

    get selectedPlaylistEditable() {
        if (this.playlistListModel.selectedPlaylist) {
            return this.playlistListModel.selectedPlaylist.isEditable;
        }
        return false;
    }

    get selectedPlaylistName() {
        if (this.playlistListModel.selectedPlaylist) {
            return languageManager.getTranslationForValue(this.playlistListModel.selectedPlaylist.body.name, AppUserModel.getInstance().langCode)
        }
        return "";
    }

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get isAudienceOnline(): boolean {
        if (this.cobrowseManager.projectRoom && this.audience) {
            const users = this.cobrowseManager.projectRoom!.roomUsersSO!.data.users;
            return users.findIndex((user) => user.cobrowseUserID === `${CobrowseUserType.AUDIENCE}-${this.audience!.ID}`) > -1;
        }
        return false;
    }

    get hasJoinedRoom(): boolean {
        if (this.audience) {
            const roomIdentifier: string = `${this.appUserModel.project.identifier}:${CobrowseRoomType.PORTAL}:${this.audience?.ID}`;
            return this.cobrowseManager.rooms.indexOf(roomIdentifier) >= 0;
        }
        return false;
    }

    public async mounted() {
        this._orderPlaylistsCreatedDate();
        // on init default select first playlist
        //
        EventBus.$on(EventBusActions.CREATE_PLAYLIST_FINISHED, this.createPlaylistFinished);
    }

    public beforeDestroy() {
        EventBus.$off(EventBusActions.CREATE_PLAYLIST_FINISHED, this.createPlaylistFinished);
    }

    private createPlaylistFinished(p_playlist: PlaylistModel) {
        this.playlistListModel.selectedPlaylist = p_playlist;
        this.currentOverviewState = OverviewState.PLAYLIST_ENTRIES;
    }

    public async _onPlaylistClick(p_playlist: PlaylistModel, p_e: Event) {
        this.playlistListModel.selectedPlaylist = p_playlist;
        this.currentOverviewState = OverviewState.PLAYLIST_ENTRIES;
    }

    public async _onBackBtnClick(p_playlist: PlaylistModel, p_e: Event) {
        this.currentOverviewState = OverviewState.PLAYLISTS;
    }

    public _onCreatePlaylistBtnClick(p_e: Event) {
        const newPlaylist: PlaylistModel = new PlaylistModel();
        newPlaylist.body.name[AppUserModel.getInstance().langCode] = this.$t("NewPlaylist") as string;
        newPlaylist.body.poster[AppUserModel.getInstance().langCode] = `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/presentation_poster_default.png`; //todo default poster image
        EventBus.$emit(EventBusActions.ASK_CREATE_EDIT_PLAYLIST, newPlaylist);
    }

    public async _onPlaylistEntryClick(p_playlistEntry: IPlaylistEntryDto) {
        console.log("_onPlaylistEntryClick", p_playlistEntry);
        // @todo TomV: start playlist at position clicked list?
        // this.playlistListModel.selectedQueueEntry = p_playlistEntry;
        // EventBus.$emit(EventBusActions.ASK_PRESENT_PLAYLIST, this.playlistListModel.selectedPlaylist);
    }

    public _onEditPlaylistBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_CREATE_EDIT_PLAYLIST, this.playlistListModel.selectedPlaylist);
    }

    // ordering
    public _orderPlaylistsAlphabetClick() {
        if (this.orderedAlphabet) {
            this._orderPlaylistsCreatedDate();
            this.orderedAlphabet = false;
        } else {
            this._orderPlaylistsAlphabet();
            this.orderedAlphabet = true;
        }
    }

    private _orderPlaylistsAlphabet() {
        this.playlists.sort((a: PlaylistModel, b: PlaylistModel) => {
            if (languageManager.getTranslationForValue(a.body.name, AppUserModel.getInstance().langCode).toLowerCase() < languageManager.getTranslationForValue(b.body.name, AppUserModel.getInstance().langCode).toLowerCase()) {
                return -1;
            }
            if (languageManager.getTranslationForValue(a.body.name, AppUserModel.getInstance().langCode).toLowerCase() > languageManager.getTranslationForValue(b.body.name, AppUserModel.getInstance().langCode).toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    private _orderPlaylistsCreatedDate() {
        this.playlists.sort((a: PlaylistModel, b: PlaylistModel) => {
            let dateA: Date | null = null;
            if (a.createdDate instanceof Date) {
                dateA = a.createdDate;
            } else {
                dateA = new Date(a.createdDate);
            }
            let dateB: Date | null = null;
            if (b.createdDate instanceof Date) {
                dateB = b.createdDate;
            } else {
                dateB = new Date(b.createdDate);
            }
            if (dateA > dateB) {
                return -1;
            }
            if (dateA < dateB) {
                return 1;
            }
            return 0;
        });
    }

    public toggle() {
        this.appModel.togglePlaylistOverviewOpen();
    }

    public _onDragOver(p_event: DragEvent) {
        let currentDragged = ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentModel) {
            p_event.preventDefault();
            if (p_event.dataTransfer) {
                p_event.dataTransfer.dropEffect = "move";
            }
        }
    }

    public _onDragEnter(p_event: DragEvent) {
        this.dropFinished = false;
        this.dragCount++;
        this.isBeingDraggedOver = true;
    }

    public _onDragLeave(p_event: DragEvent) {
        this.dragCount--;
        if (this.dragCount == 0) {
            this.isBeingDraggedOver = false;
        }
    }

    public _onDrop() {
        let currentDragged = ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentModel && this.playlistListModel.selectedPlaylist) {
            playlistController.addContentToPlaylist(this.playlistListModel.selectedPlaylist, currentDragged);
        }
        contentFolderController.endDrag()
        // reset drop count hack
        this.dragCount = 0;
        this.isBeingDraggedOver = false;
        // do animation
        this.dropFinished = true;
    }

    public async _onPresentContentBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_PRESENT_PLAYLIST, this.playlistListModel.selectedPlaylist);
    }

    public async _onPlaylistDotsClick() {
        await Vue.nextTick();
        const el: any = this.$el.querySelector(`#playlist-${this.playlistListModel.selectedPlaylist!.playlistID}`);
        if (el) {
            el.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }

    @Watch('playlistOverviewOpen', {immediate: false, deep: false})
    private _onPlaylistOpenStateChange(newAudience: AudienceModel | null, oldAudience: AudienceModel | null) {
        if (!this.playlistOverviewOpen) {
            this.currentOverviewState = OverviewState.PLAYLISTS;
        }
    }

}
</script>
