<template>
  <div class="main-menu">
    <router-link :to="{ name: routingIdentifiers.CONTENT_OVERVIEW }" :class="{ active: isActive(routingIdentifiers.HOME) }" class="main-menu-item">
      <span>{{ $t("MainMenuDashboard") }}</span>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.ANALYTICS }"
                 :class="{ disabled: !online, active: isActive(routingIdentifiers.ANALYTICS) }" class="main-menu-item" v-if="showAnalytics">
      <span>{{ $t("MainMenuAnalytics") }}</span>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.ASSETS }" :class="{ disabled: !online, active: isActive(routingIdentifiers.LIBRARY) }" class="main-menu-item">
      <span>{{ $t("MainMenuLibrary") }}</span>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.HELP }" :class="{ disabled: !online, active: isActive(routingIdentifiers.HELP) }" class="main-menu-item">
      <span>{{ $t("MainMenuHelp") }}</span>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.TEAMUSERS }"
                 :class="{ disabled: !online, active: isActive(routingIdentifiers.PEOPLE) }" class="main-menu-item main-menu-item--settings" v-if="showTeam">
      <span>{{ $t("MainMenuTeam") }}</span>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.APP_USER_2 }" :class="{ disabled: !online, active: isActive(routingIdentifiers.ACCOUNT) }" class="main-menu-item main-menu-item--settings">
      <span>{{ $t("MainMenuAccount") }}</span>
    </router-link>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {RoutingIdentifier} from "@/router";
import AppUserModel from '../../project/user/_model/AppUserModel';
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import SyncStatusWidget from "@/sync/_view/SyncStatusWidget.vue";
import SyncInternetWidget from "@/sync/_view/SyncInternetWidget.vue";
import App from "@/_view/App.vue";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import {Route} from "vue-router";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {}
})
export default class MainMenu extends Vue {

  private routingIdentifiers = RoutingIdentifier;
  public appUser: AppUserModel = AppUserModel.getInstance();
  // @ts-ignore
  public currentRoute: Route = {};
  public networkManager: NetworkManager = NetworkManager.getInstance();

  get online() {
    return this.networkManager.online;
  }

  get showAnalytics(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_PROJECT_ANALYTICS.identifier) >= 0 ||
        (AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_TEAM_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasUsageInsights) ||
        (AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_USER_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasUsageInsights) ||
        (AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_CONTENT_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasContentInsights);
  }

  get showTeam(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_USERS.identifier) >= 0;
  }

  @Watch('$route', {immediate: true, deep: true})
  onUrlChange(route: Route) {
    this.currentRoute = route;
  }

  public isActive(routeIdentifier: string) {
    if (this.currentRoute) {
      return this.currentRoute.matched.some(route => route.name === routeIdentifier);
    }
    return false;
  }

}
</script>
