<template>
  <div class="list-item audience-item"
       :class="{ active: isSelected, 'body-loaded': bodyLoaded, online: showOnlineIndicator && isOnline, joined: hasJoinedRoom }"
       v-on:click="_onClick">

    <transition name="fade">
      <div class="list-item__multi-select" v-if="multiSelectActivated">
        <i class="far fa-circle" v-if="!alsoSelected"></i>
        <i class="fas fa-check-circle" v-if="alsoSelected"></i>
      </div>
    </transition>

    <div class="list-item__icon">
      <AAProfilePicture :name="audience.displayName" :imageUri="audience.avatarFileUri"></AAProfilePicture>
      <div class="list-item__icon__badges">
        <span class="badge badge-success" v-if="isNew">{{ $t('ContentItemDeltaNew') }}</span>
        <span class="badge badge-info" v-if="isUpdated">{{ $t('ContentItemDeltaUpdated') }}</span>
        <span class="badge badge-danger" v-if="isDeleted">{{ $t('ContentItemDeltaDeleted') }}</span>
      </div>
      <div class="audience-item__state" v-if="showOnlineIndicator">
        <div v-if="isOnline" class="audience-item__state__online"><i class="fa-solid fa-circle-check"></i></div>
<!--        <div v-else class="audience-item__state__offline"><i class="fa-regular fa-circle-xmark"></i></div>-->
      </div>
    </div>

    <div class="list-item__info">
      <div class="list-item__info__skeleton" v-if="!bodyLoaded">
        <div class="bar mb-1"></div>
        <div class="bar"></div>
      </div>
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ audience.displayName }}</h6>
      <p class="list-item__info__secondary mb-0" v-line-clamp="1" v-if="bodyLoaded && hasCompany">{{ companyName }}</p>
    </div>

    <transition name="fade">
      <div class="audience-item__connect btn btn-success btn-sm"
           v-if="showOnlineIndicator && isOnline && !hasJoinedRoom" @click="_onConnectBtnClick" v-html="$t('CobrowseConnect')">
      </div>
    </transition>

    <MorePopup :id="itemId" placement="right">
      <EntityActionBox :entity="audience" :editAllowed="true"/>
    </MorePopup>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import AudienceModel from "@/audience/_model/AudienceModel";
import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import CompanyListModel from "@/company/_model/CompanyListModel";
import CompanyModel from "@/company/_model/CompanyModel";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import {IAnalyticsUsageDto} from "@/analytics/_model/analytics.dto";
import {
    CobrowseUserType
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";
import appUserController from "@/project/user/_controller/AppUserController";
import {CobrowseRoomType} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
    components: {
        AAProfilePicture, MorePopup, EntityActionBox
    }
})
export default class AudienceItemRenderer extends Vue {

    @Prop() public audience!: AudienceModel;
    @Prop() private selState!: EntitySelectionState<AudienceModel>;
    @Prop() private itemId!: string;


    public cobrowseManager: CobrowseManager = CobrowseManager.getInstance();
    public appUserModel: AppUserModel = AppUserModel.getInstance();

    get audienceID() {
        return this.audience.ID;
    }

    get hasCompany(): boolean {
        return CompanyListModel.getInstance().getEntityByID(this.audience.companyID) !== null;
    }

    get companyName() {
        const company: CompanyModel | null = CompanyListModel.getInstance().getEntityByID(this.audience.companyID);
        return company ? company.displayName : "";
    }

    get bodyLoaded() {
        return this.audience.loadingStatus >= LoadingStatus.BODY_LOADED;
    }

    get isSelected() {
        return this.selState && this.selState.selected === this.audience;
    }

    get alsoSelected() {
        return this.selState && this.selState.checkSelected(this.audience);
    }

    get multiSelectActivated() {
        return this.selState && this.selState.isMultiSelect;
    }

    get isNew() {
        return this.audience.deltaStatus == DeltaStatus.NEW;
    }

    get isUpdated() {
        return this.audience.deltaStatus == DeltaStatus.UPDATED;
    }

    get isDeleted() {
        return this.audience.deltaStatus == DeltaStatus.DELETED;
    }

    get showOnlineIndicator(): boolean {
        return !!this.cobrowseManager.projectRoom;
    }

    get isOnline(): boolean {
        if (this.showOnlineIndicator) {
            const users = this.cobrowseManager.projectRoom!.roomUsersSO!.data.users;
            return users.findIndex((user) => user.cobrowseUserID === `${CobrowseUserType.AUDIENCE}-${this.audienceID}`) > -1;
        }
        return false;
    }

    get hasJoinedRoom(): boolean {
        const roomIdentifier: string = `${this.appUserModel.project.identifier}:${CobrowseRoomType.PORTAL}:${this.audienceID}`;
        return this.cobrowseManager.rooms.indexOf(roomIdentifier) >= 0;
    }


    public _onClick(p_e: Event) {
        if (this.bodyLoaded) {
            this.$emit("onClick", this.audience);
        }
    }

    public _onConnectBtnClick(p_e: Event) {
        appUserController.joinPortalRoom(this.audienceID);
    }

}
</script>
