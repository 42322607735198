<template>
  <div class="main-sub-page account-page">

    <div class="sub-menu-bar">
      <div class="sub-menu">
        <router-link :to="{ name: routingIdentifiers.APP_USER_2 }" class="sub-menu-item">
          <i class="fas fa-user"></i>
          <h6 v-html="$t('MainMenuProfileSettings')"></h6>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.PROJECT }" class="sub-menu-item"
                     v-if="showProjectSettings">
          <i class="fas fa-cog"></i>
          <h6 v-html="$t('MainMenuProjectSettings')"></h6>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.CRM }" v-if="showCRM"
                     class="sub-menu-item">
          <i class="fas fa-id-card"></i>
          <h6 v-html="$t('MainMenuCRM')"></h6>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.COMPANIES }" v-if="showCompanies" class="sub-menu-item">
          <i class="fas fa-building"></i>
          <h6 v-html="$t('Companies')"></h6>
        </router-link>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CreateTeamUserBox from "@/team/_view/CreateTeamUserBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RoutingIdentifier} from "@/router";
import roleController from "@/team/_controller/RoleController";
import {RIGHTS} from "@/team/_model/role.constants";
import {IProjectCustomPageDto} from "@/project/_model/project.dto";
import languageManager from "@/__libs/language_manager/LanguageManager";

@Component({
    components: {}
})
export default class AccountPage extends Vue {

    private routingIdentifiers = RoutingIdentifier;
    public appUser: AppUserModel = AppUserModel.getInstance();


    get showProjectSettings(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.EDIT_PROJECT_SETTINGS.identifier) >= 0;
    }

    get showCompanies(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_COMPANIES.identifier) >= 0;
    }

    get showCRM(): boolean {
        return Object.keys(this.appUser.project.crmProviders).length > 0;
    }

}
</script>
