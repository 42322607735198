<template>
  <div class="project-settings box box--full-height box--scrollable" v-if="project">

    <h4 class="title mt-1 mb-4">{{ $t('ProjectSettingsTitle') }}</h4>

    <b-form-group class="mb-5">
      <b-form-input size="lg"
                    v-model="validationProject.displayName"
                    :placeholder="$t('PlaceholderDisplayName')"
                    :state="calculateInputState($v.validationProject.displayName)"
                    @input="project.displayName = onDisplayNameInput($v.validationProject.displayName, project.displayName)"
                    tabindex="1"></b-form-input>
      <b-form-invalid-feedback>
        <span class="d-block"
              v-if="!$v.validationProject.displayName.required">{{
            $t('FieldRequiredSimple')
          }}</span>
        <span class="d-block"
              v-if="!$v.validationProject.displayName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <h5 class="title mb-3">{{ $t('ProjectSettingsLogoTitle') }}</h5>
    <ProjectLogos class="mb-5"></ProjectLogos>

    <h5 class="title mb-3">{{ $t('ProjectSettingsColorTitle') }}</h5>
    <ProjectColors class="mb-5"></ProjectColors>

    <h5 class="title mb-3">{{ $t('ProjectSettingsFontTitle') }}</h5>
    <ProjectFonts class="mb-5"></ProjectFonts>

    <b-button variant="primary" block @click="redoWelcomeFlow">
      {{ $t('ProjectRedoWelcomeFlow') }}
    </b-button>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import projectController from "@/project/_controller/ProjectController";
import {minLength, required} from "vuelidate/lib/validators";
import ProjectLogos from "@/project/_view/ProjectLogos.vue";
import ProjectColors from "@/project/_view/ProjectColors.vue";
import ProjectFonts from "@/project/_view/ProjectFonts.vue";
import {RoutingIdentifier} from "@/router";

@Component({
  components: {ProjectFonts, ProjectColors, ProjectLogos},
  validations: {
    validationProject: {
      displayName: {
        required,
        minLength: minLength(3)
      }
    }
  }
})
export default class ProjectSettings extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;
  private validationProject: ProjectModel = new ProjectModel();
  private hasChanges: boolean = false;

  public mounted() {
    this.createValidationProject();
    window.addEventListener('beforeunload', this.save);
  }

  public beforeDestroy() {
    this.save();
    window.removeEventListener('beforeunload', this.save);
  }

  private createValidationProject(): void {
    if (this.project) {
      this.validationProject = new ProjectModel();
      this.validationProject.displayName = this.project.displayName;
    }
  }

  private calculateInputState(inputValidator: any): any {
    return inputValidator.$invalid ? false : null;
  }

  private onDisplayNameInput(inputValidator: any, value: any): void {
    if (!inputValidator.$invalid && this.project) {
      value = inputValidator.$model;
      this.hasChanges = true;
    }
    return value;
  }

  private save() {
    if (this.hasChanges) {
      projectController.saveProject();
    }
  }

  private async redoWelcomeFlow() {
    await this.$router.push({name: RoutingIdentifier.WELCOME_LOGO});
  }

}
</script>

