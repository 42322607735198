<template>
  <div class="list-item asset-folder-item"
       :class="{ active: isSelected, 'body-loaded': bodyLoaded }"
       v-cloak @drop.prevent="_onDropFile" @dragover.prevent @dragenter="_onDragOver" @dragleave="_onDragStop"
       v-on:click="_onClick">

    <div class="list-item__icon">
      <i class="far fa-folder fa-2x" v-if="!isSelected"></i>
      <i class="far fa-folder-open fa-2x" v-if="isSelected"></i>
    </div>

    <div class="list-item__info">
      <div class="list-item__info__skeleton" v-if="!bodyLoaded">
        <div class="bar"></div>
      </div>
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ assetFolderName }}</h6>
    </div>

    <MorePopup :id="`asset-folder-${assetFolderID}`" placement="right">
      <EntityActionBox :entity="assetFolder" :editAllowed="false"/>
    </MorePopup>

    <transition name="fade">
      <div class="drop-area" v-if="showDropIndication">
        <i class="fas fa-plus-circle fa-lg"></i>
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
import {RightAction} from "@/team/_model/role.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import {FileType} from "@/asset_folder/_model/asset_folder.constants";
import FileUtil from "@/__libs/utility/FileUtil";
import fileSize from "filesize";

@Component({
  components: {
    MorePopup, EntityActionBox
  }
})
export default class AssetFolderItemRenderer extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() public assetFolder!: AssetFolderModel;

  @Prop() private selState!: AssetFolderSelectionState;

  //---------------------------------
  // Vue Component data
  //---------------------------------


  private showDropIndication: boolean = false;
  private dropAreaCounter: number = 0;


  //---------------------------------
  // Vue Computed properties
  //---------------------------------

  get assetFolderName() {
    return languageManager.getTranslationForValue<string>(this.assetFolder.name, AppUserModel.getInstance().langCode)
  }

  get assetFolderID() {
    return this.assetFolder.ID;
  }

  get bodyLoaded() {
    return this.assetFolder.loadingStatus >= LoadingStatus.BODY_LOADED;
  }

  get isSelected() {
    return this.selState.selected === this.assetFolder;
  }


  get isNew() {
    return this.assetFolder.deltaStatus == DeltaStatus.NEW;
  }

  get isUpdated() {
    return this.assetFolder.deltaStatus == DeltaStatus.UPDATED;
  }

  get isDeleted() {
    return this.assetFolder.deltaStatus == DeltaStatus.DELETED;
  }

  get isEditable(): boolean {
    return this.assetFolder.rightActions.indexOf(RightAction.EDIT) >= 0;
  }

  get inputAccept(): string {
    return AssetFolderListModel.getExtensionsForFileTypes(this.selState.allowedFileTypes).join();
  }

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------


  //---------------------------------
  // Private / helper methods
  //---------------------------------

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.assetFolder);
  }


  private async _onDropFile(e: DragEvent) {
    if (this.isEditable) {
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        //
        if (await this.checkValidFilesSelect(e.dataTransfer.files)) {
          await assetFolderController.addFile(this.assetFolder, e.dataTransfer.files, this.selState);
        }
      }
      this._onDragStop(e);
    }
  }

  private _onDragOver(e: DragEvent) {
    this.dropAreaCounter++;
    if (this.isEditable) {
      this.showDropIndication = true;
    }
  }

  private _onDragStop(e: DragEvent) {
    this.dropAreaCounter--;
    if (this.dropAreaCounter === 0) {
      this.showDropIndication = false;
    }
  }

  //todo: WARNING: this code is duplicated in AssetFilesOverview
  private async checkValidFilesSelect(p_files: FileList): Promise<boolean> {
    if (p_files.length > AssetFolderListModel.MAX_SIMULTANEOUS_UPLOADS) {
      await this.$bvModal.msgBoxOk(this.$t('MaxSimultaneousUploads', [AssetFolderListModel.MAX_SIMULTANEOUS_UPLOADS]) as string, {centered: true});

      return false;
    } else {
      for (let i = 0; i < p_files.length; i++) {
        const file: File | null = p_files.item(i);

        if (file) {
          const fileType: FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(file.name));
          if (fileType > FileType.UNKNOWN) {
            if (file.size > AppUserModel.getInstance().project.tierConfig.maxFileSize) {
              await this.$bvModal.msgBoxOk(this.$t('ErrorMaxSize', [fileSize(AppUserModel.getInstance().project.tierConfig.maxFileSize)]) as string, {centered: true});
              return false;
            }
          } else {
            await this.$bvModal.msgBoxOk(this.$t('ErrorFileType', [this.inputAccept]) as string, {centered: true});
            return false;
          }
        }
      }
    }
    return true;
  }

}
</script>
