<template>
  <div class="activity-detail">

    <transition name="fade">
      <div v-if="!activity"
           class="empty-message">
        {{ $t('TrackPageActivityDetailPlaceholderMessage') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="activity" :key="activity.startDate" class="activity-detail__wrapper">

        <transition name="fade">
          <div v-if="activity.activityType === activityType.UNSHARED_CONTENT" :key="activity.identifier"
               class="activity-detail__detail-simple">
            <h2 class="mt-1 mb-1 title">{{ contentName }}</h2>
            <div class="box box--full-height box--scrollable">
              <div class="activity-detail__date"
                   v-html="$t('ActivityTextUnshared', [$d(new Date(activity.startDate), 'long')])"></div>
              <AAImage class="activity-detail__image" :imageUrl="contentPoster"></AAImage>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="activity.activityType === activityType.PRESENTATION_SESSION && this.loading"
               :key="activity.identifier"
               class="empty-loader">
            <b-spinner variant="dark"></b-spinner>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="activity.activityType === activityType.PRESENTATION_SESSION && !this.loading"
               :key="activity.identifier"
               class="activity-detail__detail-presentation">
            <div class="mt-1 mb-1">
              <div class="activity-detail__title">
                <h2 class="mb-1 title">{{ contentName }}</h2>
                <span class="badge aa-content-item-type aa-content-item-type--presentation">{{
                    $t('ContentItemTypes_PRESENTATION')
                  }}</span>
              </div>
              <div v-html="$d(new Date(activity.startDate), 'long')"></div>
            </div>
            <div class="box activity-detail__square activity-detail__square--image">
              <div class="responsive responsive--square">
                <div class="responsive__wrapper">
                  <AAImage :imageUrl="contentPoster"></AAImage>
                </div>
              </div>
            </div>
            <div class="box activity-detail__square activity-detail__square--number">
              <div class="responsive responsive--square">
                <div class="responsive__wrapper">
                  <div class="number-value">{{
                      new Date(subjectTotalDuration * 1000).toISOString().slice(14, 19)
                    }}
                  </div>
                  <div class="text-value">{{ $t('ActivityTotalTime') }}</div>
                </div>
              </div>
            </div>
            <div class="box activity-detail__square activity-detail__square--number">
              <div class="responsive responsive--square">
                <div class="responsive__wrapper">
                  <div class="number-value">{{ subjectPercentageViewed }}<span>%</span></div>
                  <div class="text-value">{{ $t('ActivityViewsConsulted') }}</div>
                </div>
              </div>
            </div>
            <div class="box activity-detail__square activity-detail__square--number">
              <div class="responsive responsive--square">
                <div class="responsive__wrapper">
                  <div class="number-value">{{
                      new Date(subjectViewLongestDuration * 1000).toISOString().slice(14, 19)
                    }}
                  </div>
                  <div class="text-value">{{ $t('ActivityLongestTime') }}</div>
                </div>
              </div>
            </div>
            <div class="box activity-detail__presentation-player">
              <div class="guide">
                <div class="guide__square short"></div>
                <span>{{ $t('ActivityShortDuration') }}</span>
                <div class="guide__square medium"></div>
                <span>{{ $t('ActivityMediumDuration') }}</span>
                <div class="guide__square long"></div>
                <span>{{ $t('ActivityLongDuration') }}</span>
              </div>
              <div class="player-wrapper">
                <PresentationPlayer class="analytics-player"
                                    engineMode="ANALYTICS"
                                    :audienceSessionIdentifier="activity.identifier"
                                    :useConstruct="activity.payload.construct"/>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="activity.activityType === activityType.SHARED_CONTENT" :key="activity.identifier"
               class="activity-detail__detail-simple">
            <h2 class="mt-1 mb-1 title">{{ contentName }}</h2>
            <div class="box box--full-height box--scrollable">
              <template v-if="activity.payload">
                <p class="mb-4">{{ $t('ActivityTextSharedContentEmail', [$d(new Date(activity.startDate), 'long')]) }}
                  <small class="d-block" v-if="activity.payload.putMeInCC">{{
                      $t('ActivityTextSharedContentEmailCC')
                    }}</small></p>
                <h6 class="font-weight-bold">{{ activity.payload.subject }}</h6>
                <p>{{ activity.payload.body }}</p>
              </template>
              <template v-else>
                <p>{{ $t('ActivityTextSharedContentLink', [$d(new Date(activity.startDate), 'long')]) }}</p>
              </template>
              <AAImage class="activity-detail__image" :imageUrl="contentPoster"></AAImage>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
              v-if="activity.activityType === activityType.FILE_SESSION"
              :key="activity.identifier" class="activity-detail__detail-simple">
            <div class="activity-detail__title">
              <h2 class="mb-1 title">{{ contentName }}</h2>
              <span class="badge aa-content-item-type aa-content-item-type--content_file"
                    v-if="activity.activityType === activityType.FILE_SESSION">{{
                  $t('ContentItemTypes_CONTENT_FILE')
                }}</span>
            </div>
            <div class="box box--full-height box--scrollable">
              <PDFViewer
                  v-if="isPDF"
                  engMode="ANALYTICS"
                  :sessionIdentifier="activity.identifier"/>
              <div v-if="!isPDF">
                <div class="activity-detail__date"><span>{{ $t('viewed') }}:</span>
                  <strong>{{ $d(new Date(activity.startDate), 'long') }}</strong></div>
                <AAImage class="activity-detail__image" :imageUrl="contentPoster"></AAImage>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if=" activity.activityType === activityType.APP_SESSION"
               :key="activity.identifier" class="activity-detail__detail-simple">
            <div class="activity-detail__title">
              <h2 class="mb-1 title">{{ contentName }}</h2>
              <span class="badge aa-content-item-type aa-content-item-type--content_app">{{
                  $t('ContentItemTypes_CONTENT_APP')
                }}</span>

            </div>
            <div class="box box--full-height box--scrollable position-relative">

              <div v-if="!isCustomAnalyticsContentApp">
                <div class="activity-detail__date"><span>{{ $t('viewed') }}:</span>
                  <strong>{{ $d(new Date(activity.startDate), 'long') }}</strong></div>
                <AAImage class="activity-detail__image" :imageUrl="contentPoster"></AAImage>
              </div>
              <ContentAppPlayer v-if="isCustomAnalyticsContentApp" class="content-app-player"
                                engineMode="ANALYTICS"
                                appType="HTML_APP"
                                :sessionIdentifier="activity.identifier"
                                :appID="activity.subjectIdentifier"/>
            </div>
          </div>
        </transition>


        <transition name="fade">
          <div v-if="activity.activityType === activityType.AUDIENCE_FILE_SESSION" :key="activity.identifier"
               class="activity-detail__detail-simple">
            <h2 class="mt-1 mb-1 title">{{ audienceFileName }}</h2>
            <div class="box box--full-height box--scrollable">
              <div class="activity-detail__date"><span>{{ $t('viewed') }}:</span>
                {{ $d(new Date(activity.startDate), 'long') }}
              </div>
              <AAImage class="activity-detail__image" :imageUrl="audienceFileThumb"></AAImage>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="activity.activityType === activityType.PORTAL_SESSION" :key="activity.identifier"
               class="activity-detail__detail-simple">
            <div class="activity-detail__date mb-1" v-if="activity.payload"
                 v-html="$t('ActivityTextPortalGuestSession', [activity.payload.displayName, audience.displayName, $d(new Date(activity.startDate), 'long')])"></div>
            <div class="activity-detail__date mb-1" v-else
                 v-html="$t('ActivityTextPortalSession', [audience.displayName, $d(new Date(activity.startDate), 'long')])"></div>
            <div class="box box--full-height">
              <iframe :src="portalLink"></iframe>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="activity.activityType === activityType.GUEST_SHARE" :key="activity.identifier"
               class="activity-detail__detail-simple">
            <div class="activity-detail__date mb-1"
                 v-html="$t('ActivityTextGuestShare', [audience.displayName, activity.payload, $d(new Date(activity.startDate), 'long')])"></div>
            <div class="box box--full-height">
              <iframe :src="portalLink"></iframe>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="activity.activityType === activityType.PORTAL_VISIT_DENIED" :key="activity.identifier"
               class="activity-detail__detail-simple">
            <div class="activity-detail__date mb-1"
                 v-html="$t('ActivityTextPortalVisitDenied', [audience.displayName, $d(new Date(activity.startDate), 'long')])"></div>
          </div>
        </transition>

      </div>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import SelectionState from "@/__libs/_model/SelectionState";
import {IAudienceActivityDto} from "@/audience/track/_model/audience_activity.dto";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import PresentationPlayer from "@/presentation/player/_view/PresentationPlayer.vue";
import AppModel from "@/_model/AppModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import FileUtil from "@/__libs/utility/FileUtil";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import {FILE_TYPE_CONFIG, FileType} from "@/asset_folder/_model/asset_folder.constants";
import fileManager from "@/_controller/FileManager";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AAImage from "@/_view/components/AAImage.vue";
import PDFViewer from "@/_view/components/PDFViewer.vue";
import {IAudienceSessionDto} from "@/audience/session/_model/audience_session.dto";
import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
import AudienceSessionModel from "@/audience/session/_model/AudienceSessionModel";
import PresentationModel from "@/presentation/_model/PresentationModel";
import contentController from "@/content/_controller/ContentController";
import contentAppController from "@/content_app/_controller/ContentAppController";
import {IContentAppResourceDto} from "@/content_app/resource/_model/content_app_resource.dto";
import ContentAppPlayer from "@/content_app/player/_view/ContentAppPlayer.vue";

@Component({
    components: {PresentationPlayer, AAImage, PDFViewer, ContentAppPlayer}
})
export default class ActivityDetail extends Vue {

    private activityType: typeof ActivityType = ActivityType;

    @Prop() private selState!: SelectionState<IAudienceActivityDto>;

    private audienceSession: AudienceSessionModel = new AudienceSessionModel();

    private loading: boolean = false;

    private isCustomAnalyticsContentApp: boolean = false;

    get audience(): AudienceModel {
        return AudienceListModel.getInstance().globalSelState.selected!;
    }

    get activity(): IAudienceActivityDto {
        return this.selState.selected!;
    }

    get portalLink(): string {
        if (this.audience) {
            return `${AppModel.getInstance().global.storageUrl}/portal/index.html?apiUrl=${AppUserModel.getInstance().project.apiUrl}&portalToken=${this.audience.portalToken}&isPreview=1`;
        }
        return "";
    }

    get contentPoster(): string {
        if (this.activity.subjectPoster) {
            return fileManager.getFileUrl(languageManager.getTranslationForValue(
              this.activity.subjectPoster,
              this.audience.langCode));
        }
        return "";
    }

    get contentName(): string {
        if (this.activity.subjectName) {
            return languageManager.getTranslationForValue(
              this.activity.subjectName,
              this.audience.langCode
            );
        }
        return "";
    }

    get subjectTotalDuration(): number {
        if (this.activity.activityType === ActivityType.PRESENTATION_SESSION) {
            return this.audienceSession.getTotalDuration(ActivityType.SLIDE_SESSION);
        }
        return 0;
    }

    get subjectViewLongestDuration(): number {
        if (this.activity.activityType === ActivityType.PRESENTATION_SESSION) {
            return this.audienceSession.getLongestSessionDuration(ActivityType.SLIDE_SESSION);
        }
        return 0;
    }

    get subjectPercentageViewed(): number {
        if (this.activity.activityType === ActivityType.PRESENTATION_SESSION) {
            return this.audienceSession.getPercentageOfContentViewsViewed();
        }
        return 0;
    }

    get audienceFileName(): string {
        return FileUtil.getFriendlyFileName(this.activity.subjectIdentifier as string);
    }

    get audienceFileThumb(): string {
        const fileName: string = this.activity.subjectIdentifier as string;
        const fileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(fileName));
        if (fileType === FileType.IMAGE) {
            return AppUserModel.getInstance().project.storageUrl + '/audiences/files/' + this.activity.payload.audienceFileCategoryType + '/' + fileName;
        } else {
            return FILE_TYPE_CONFIG[fileType].posterUrl;
        }
    }

    get isPDF(): boolean {
        return !!(this.activity.payload && this.activity.payload.fileType && this.activity.payload.fileType === FileType.PORTABLE_DOCUMENT);
    }


    private async fetchPresentationSessionData(): Promise<void> {
        const audienceSessionDto: IAudienceSessionDto | null = await audienceSessionController.getAudienceSession(this.activity.identifier as string);
        if (audienceSessionDto) {
            this.audienceSession = new AudienceSessionModel();
            this.audienceSession.mapFromDto(audienceSessionDto);
            this.audienceSession.subject = new PresentationModel();
            this.audienceSession.subject.ID = this.audienceSession.subjectIdentifier;
            await contentController.fetchBody(this.audienceSession.subject);
        }
    }

    @Watch('selState.selected', {immediate: true, deep: false})
    private async _onSelstateChange(newSelected: IAudienceActivityDto | null, oldSelected: IAudienceActivityDto | null) {
        if (newSelected && newSelected.activityType === this.activityType.PRESENTATION_SESSION) {
            this.loading = true;
            await this.fetchPresentationSessionData();
            this.loading = false;
        }
        if (newSelected && newSelected.activityType === this.activityType.APP_SESSION) {
            this.loading = true;
            await this.resolveCustomAnalyticsMode();
            this.loading = false;
        }
    }

    private async resolveCustomAnalyticsMode(): Promise<void> {
        const resourceConfig: IContentAppResourceDto | null = await contentAppController.getResourceConfigForApp(this.activity.subjectIdentifier as string);
        if (resourceConfig && resourceConfig.hasCustomAnalyticsMode) {
            this.isCustomAnalyticsContentApp = true;
        }else{
            this.isCustomAnalyticsContentApp = false;
        }
    }


}
</script>
