<template>
  <div class="list-item slide-item"
       :class="{ active: isSelected, 'body-loaded': bodyLoaded }"
       v-on:click="_onClick">

    <div class="list-item__icon">
      <i class="far fa-sticky-note fa-2x"></i>
    </div>

    <div class="list-item__info">
      <div class="list-item__info__skeleton" v-if="!bodyLoaded">
        <div class="bar"></div>
      </div>
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ slideName }}</h6>
    </div>

    <MorePopup :id="`slide-${slideID}`" placement="right">
      <EntityActionBox :entity="slide" :editAllowed="true"/>
    </MorePopup>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import SlideModel from "@/slide/_model/SlideModel";
import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import slideController from "@/slide/_controller/SlideController";
import {RightAction} from "@/team/_model/role.constants";

@Component({
  components: {
    MorePopup, EntityActionBox
  }
})
export default class SlideItemRenderer extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() public slide!: SlideModel;

  @Prop() private selState!: SlideSelectionState;

  //---------------------------------
  // Vue Component data
  //---------------------------------

  //---------------------------------
  // Vue Computed properties
  //---------------------------------

  get slideName() {
    return languageManager.getTranslationForValue<string>(this.slide.name, AppUserModel.getInstance().langCode)
  }

  get slideID() {
    return this.slide.ID;
  }

  get bodyLoaded() {
    return this.slide.loadingStatus >= LoadingStatus.BODY_LOADED;
  }

  get isSelected() {
    return this.selState.selected === this.slide;
  }

  get isNew() {
    return this.slide.deltaStatus == DeltaStatus.NEW;
  }

  get isUpdated() {
    return this.slide.deltaStatus == DeltaStatus.UPDATED;
  }

  get isDeleted() {
    return this.slide.deltaStatus == DeltaStatus.DELETED;
  }

  get isEditable(): boolean {
    return this.slide.rightActions.indexOf(RightAction.EDIT) >= 0;
  }

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  //---------------------------------
  // Private / helper methods
  //---------------------------------

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.slide);
  }

}
</script>
