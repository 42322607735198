<template>
  <div class="list-item company-item"
       :class="{ active: isSelected, 'body-loaded': bodyLoaded }"
       v-on:click="_onClick">

    <div class="list-item__icon">
      <AAProfilePicture :name="company.displayName" :imageUri="company.companyLogo"></AAProfilePicture>
      <div class="list-item__icon__badges">
        <span class="badge badge-success" v-if="isNew">{{ $t('ContentItemDeltaNew') }}</span>
        <span class="badge badge-info" v-if="isUpdated">{{ $t('ContentItemDeltaUpdated') }}</span>
        <span class="badge badge-danger" v-if="isDeleted">{{ $t('ContentItemDeltaDeleted') }}</span>
      </div>
    </div>

    <div class="list-item__info">
      <div class="list-item__info__skeleton" v-if="!bodyLoaded">
        <div class="bar"></div>
      </div>
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ company.displayName }}</h6>
    </div>

    <MorePopup :id="`company-${companyID}`" placement="right">
      <EntityActionBox :entity="company" :editAllowed="false"/>
    </MorePopup>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import CompanyModel from "@/company/_model/CompanyModel";
import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import {RightAction} from "@/team/_model/role.constants";
import SelectionState from "@/__libs/_model/SelectionState";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";

@Component({
  components: {
    AAProfilePicture, MorePopup, EntityActionBox
  }
})
export default class CompanyItemRenderer extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() public company!: CompanyModel;

  @Prop() private selState!: SelectionState<CompanyModel>;

  //---------------------------------
  // Vue Component data
  //---------------------------------

  //---------------------------------
  // Vue Computed properties
  //---------------------------------


  get companyID() {
    return this.company.ID;
  }

  get bodyLoaded() {
    return this.company.loadingStatus >= LoadingStatus.BODY_LOADED;
  }

  get isSelected() {
    return this.selState.selected === this.company;
  }

  get isNew() {
    return this.company.deltaStatus == DeltaStatus.NEW;
  }

  get isUpdated() {
    return this.company.deltaStatus == DeltaStatus.UPDATED;
  }

  get isDeleted() {
    return this.company.deltaStatus == DeltaStatus.DELETED;
  }

  get isEditable(): boolean {
    return this.company.rightActions.indexOf(RightAction.EDIT) >= 0;
  }

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  //---------------------------------
  // Private / helper methods
  //---------------------------------

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.company);
  }

}
</script>
