<template>
  <div class="base-page audience-detail-page">

    <AudienceDetail></AudienceDetail>
    <AudienceSummary></AudienceSummary>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AudienceSummary from "@/audience/_view/AudienceSummary.vue";
import AudienceDetail from "@/audience/_view/AudienceDetail.vue";

@Component({
  components: {AudienceDetail, AudienceSummary}
})
export default class AudienceDetailPage extends Vue {
}
</script>
