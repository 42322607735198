<template>
  <div class="activity-item" :class="{ active: isSelected }"
       @click="_onClick">

    <div class="activity-item__date">
      <div class="activity-item__date__day">{{ day }}</div>
      <div class="activity-item__date__month-year">{{ month }}-{{ year }}</div>
    </div>

    <div class="activity-item__dot"></div>

    <div class="activity-item__content">
      <div class="activity-item__content__simple">

        <div v-if="subjectName !== ''" v-line-clamp="1">{{ subjectName }}</div>
        <div v-else class="font-italic text-right">{{ actionLabel }}<span v-if="activity.activityType !== activityType.PORTAL_SESSION && activity.activityType !== activityType.PORTAL_VISIT_DENIED"> {{ contentItemTypeLabel }}</span></div>

      </div>
      <div class="activity-item__content__complex">

        <div class="activity-item__content__complex__part-1" v-if="activity.activityType !== activityType.PORTAL_SESSION && activity.activityType !== activityType.PORTAL_VISIT_DENIED">
          <div class="badge aa-content-item-type mb-1" :class="contentItemTypeClass">{{ contentItemTypeLabel }}</div>
          <div class="subject-name" v-line-clamp="2">{{ subjectName }}</div>
        </div>
        <div class="activity-item__content__complex__part-2">
          <h6 class="text-uppercase mb-0" v-line-clamp="1">{{ actionLabel }}</h6>
          <div class="small" v-line-clamp="1">{{ actionByLabel }}</div>
        </div>

      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import AudienceModel from "@/audience/_model/AudienceModel";
import fileManager from "@/_controller/FileManager";
import SelectionState from "@/__libs/_model/SelectionState";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import {IAudienceActivityDto} from "@/audience/track/_model/audience_activity.dto";
import {ActivityCategoryType, ActivityType} from "@/audience/track/_model/audience_activity.constants";
import AAImage from "@/_view/components/AAImage.vue";
import {EntityType} from "@/entity/_model/entity.constants";
import {FILE_TYPE_CONFIG, FileType} from "@/asset_folder/_model/asset_folder.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import {IAudienceFileDto} from "@/audience/_model/audience.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";


enum ActionBy {
    USER,
    AUDIENCE,
    CUSTOM
}

@Component({
    components: {
        AAImage
    }
})
export default class ActivityOverviewItemRenderer extends Vue {

    @Prop() public activity!: IAudienceActivityDto;
    @Prop() private selState!: SelectionState<IAudienceActivityDto>;

    private activityType: typeof ActivityType = ActivityType;

    private thumbUrl: string = "";
    private subjectName: string = "";
    private subjectType: string = "";
    private actionLabel: string = "";
    private actionByLabel: string = "";

    get isSelected() {
        return this.selState.selected === this.activity;
    }

    get day(): number {
        return new Date(this.activity.startDate).getDate();
    }

    get month(): number {
        return new Date(this.activity.startDate).getMonth() + 1;
    }

    get year(): number {
        return new Date(this.activity.startDate).getFullYear();
    }

    get audience(): AudienceModel {
        return AudienceListModel.getInstance().globalSelState.selected!;
    }

    get contentItemTypeClass() {
        if (this.subjectType) {
            return `aa-content-item-type--${this.subjectType.toLowerCase()}`;
        }
        return '';
    }

    get contentItemTypeLabel(): string {
        if (this.activity && this.subjectType) {
            return this.$t(`ContentItemTypes_${this.subjectType}`) as string;
        }
        return '';
    }

    get startDate(): string {
        if (this.activity) {
            return this.$d(new Date(this.activity.startDate), 'short');
        }
        return '';
    }

    mounted() {
        let actionBy: ActionBy = ActionBy.USER;

        if (this.activity.activityType === ActivityType.PRESENTATION_SESSION || this.activity.activityType === ActivityType.APP_SESSION || this.activity.activityType === ActivityType.FILE_SESSION || this.activity.activityType === ActivityType.AUDIENCE_FILE_SESSION) {
            if (this.activity.categoryType === ActivityCategoryType.MEETING) {
                this.actionLabel = this.$t('ActivityPresented') as string;
            } else {
                this.actionLabel = this.$t('ActivityPresentationWatched') as string;
                actionBy = ActionBy.AUDIENCE;
            }
            if (this.activity.activityType === ActivityType.AUDIENCE_FILE_SESSION) {
                this.subjectName = FileUtil.getFriendlyFileName(this.activity.subjectIdentifier as string);
                this.subjectType = EntityType.CONTENT_FILE;

                const fileName: string = this.activity.subjectIdentifier as string;
                const fileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(fileName));
                if (fileType === FileType.IMAGE) {
                    this.thumbUrl = AppUserModel.getInstance().project.storageUrl + '/audiences/files/' + this.activity.payload.audienceFileCategoryType + '/' + fileName;
                } else {
                    this.thumbUrl = FILE_TYPE_CONFIG[fileType].posterUrl;
                }
            }
        } else if (this.activity.activityType === ActivityType.SHARED_CONTENT) {
            this.actionLabel = this.$t('ActivityContentShared') as string;
        } else if (this.activity.activityType === ActivityType.UNSHARED_CONTENT) {
            this.actionLabel = this.$t('ActivityContentUnshared') as string;
        } else if (this.activity.activityType === ActivityType.PORTAL_SESSION) {
            this.subjectType = ActivityCategoryType.PORTAL;
            this.actionLabel = this.$t('ActivityLoggedIntoPortal') as string + ' ' + this.contentItemTypeLabel;
            this.thumbUrl = AppUserModel.getInstance().project.storageUrl + '/skins/default/images/portalSplashBg.jpg';
            actionBy = ActionBy.AUDIENCE;
        } else if (this.activity.activityType === ActivityType.PORTAL_VISIT_DENIED) {
            this.subjectType = ActivityCategoryType.PORTAL;
            this.actionLabel = this.$t('ActivityLoggedIntoPortalDenied') as string;
            actionBy = ActionBy.AUDIENCE;
        } else if (this.activity.activityType === ActivityType.GUEST_SHARE) {
            this.subjectType = ActivityCategoryType.PORTAL;
            this.actionLabel = this.$t('ActivityContentShared') as string;
            this.thumbUrl = AppUserModel.getInstance().project.storageUrl + '/skins/default/images/portalSplashBg.jpg';
            actionBy = ActionBy.CUSTOM;
            this.actionByLabel = this.$t('ActivityGuestSharedWith', [this.activity.payload]) as string;
        }
        if (this.activity.subjectName) {
            this.subjectName = languageManager.getTranslationForValue(
              this.activity.subjectName,
              this.audience.langCode
            );
        }

        if (this.activity.subjectPoster) {
            this.thumbUrl = fileManager.getFileUrl(languageManager.getTranslationForValue(
              this.activity.subjectPoster,
              this.audience.langCode));
        }

        if (this.activity.subjectType) {
            this.subjectType = this.activity.subjectType;
        }


        if (actionBy === ActionBy.USER) {
            this.actionByLabel = this.$t('ActivityPerformedBy', [this.$t('Myself')]) as string;
        } else if (actionBy === ActionBy.AUDIENCE) {
            if (this.activity.payload && this.activity.payload.displayName) {
                this.actionByLabel = this.$t('ActivityPerformedBy', [this.activity.payload.displayName]) as string;
            } else {
                this.actionByLabel = this.$t('ActivityPerformedBy', [this.audience.displayName]) as string;
            }
        }

    }

    public _onClick(p_e: Event) {
        this.$emit("onClick", this.activity);
    }

}
</script>
