<template>
  <div class="share-overview list-overview">

    <h4 class="mt-1 mb-4 share-overview__title" v-html="$t('DashboardSharedItemsTitle', [audienceDisplayName])"></h4>

    <b-button @click="_onPortalBtnClick" variant="primary" block class="mb-3">{{
        $t('DashboardSharedViewVisitorPageButtonText', [audience.displayName])
      }}
    </b-button>

    <div class="mb-3">
      <b-input-group :prepend="$t('LinkToPortal')">
        <b-form-input :value="getAudiencePortalLink(false)" ref="portalLink"></b-form-input>
        <b-input-group-append>
          <b-button variant="success" @click="_onCopyLinkBtnClick"><i class="fa-solid fa-copy mr-0 fa-lg"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <transition name="fade">
        <div v-if="currentCopyToClipboardState === copyToClipboardState.SUCCESS"
             class="text-success small mt-1 text-center">{{ $t('ClipboardSuccess') }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentCopyToClipboardState === copyToClipboardState.ERROR"
             class="text-danger small mb-1 text-center">{{ $t('ClipboardError') }}
        </div>
      </transition>
    </div>

    <b-form-group>
      <b-form-checkbox @input="_onNotifyPortalVisitChange"
                       v-model="audienceNotifyPortalVisit">{{
          $t('NotifyMeWhenUserVisitsPortal')
        }}
      </b-form-checkbox>
    </b-form-group>

    <div class="list-overview-items" v-if="audienceContentShares.length > 0">
      <ShareOverviewItemRenderer
          v-for="(share, index) in audienceContentShares"
          :share="share"
          :key="index"
          v-on:onClick="_onShareClick"
          :selState="selState"></ShareOverviewItemRenderer>
    </div>

    <div class="empty-message text-body" v-if="audienceContentShares.length === 0">
      {{ $t('NoItemsShared') }}
    </div>

    <b-modal id="preview-visitor-page-box" size="xl"
             :title="$t('DashboardSharedViewVisitorPageButtonText', [audienceDisplayName]) " hide-footer>
      <PreviewVisitorPageBox v-if="audience"
                             :portalLink="getAudiencePortalLink(true)"
      ></PreviewVisitorPageBox>
    </b-modal>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import SelectionState from "@/__libs/_model/SelectionState";
import {IEntityAudienceShareOutlineDto} from "@/entity/_model/entity.dto";
import {IAudienceFileDto} from "@/audience/_model/audience.dto";
import PreviewVisitorPageBox from "@/audience/_view/PreviewVisitorPageBox.vue";
import AppModel from "@/_model/AppModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AudienceItemRenderer from "@/audience/_view/AudienceItemRenderer.vue";
import ShareOverviewItemRenderer from "@/audience/share/_view/ShareOverviewItemRenderer.vue";
import audienceController from "@/audience/_controller/AudienceController";

enum CopyToClipboardState {
    IDLE,
    SUCCESS,
    ERROR
}

@Component({
    components: {AudienceItemRenderer, PreviewVisitorPageBox, ShareOverviewItemRenderer}
})
export default class ShareOverview extends Vue {

    @Prop() private selState!: SelectionState<IEntityAudienceShareOutlineDto | IAudienceFileDto>;

    private copyToClipboardState: typeof CopyToClipboardState = CopyToClipboardState;
    private currentCopyToClipboardState: CopyToClipboardState = CopyToClipboardState.IDLE;

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get audienceDisplayName(): string {
        return this.audience?.displayName ?? '';
    }

    get audienceContentShares(): IEntityAudienceShareOutlineDto[] | IAudienceFileDto[] {
        return this.audience?.contentShares ?? [];
    }

    get audienceNotifyPortalVisit(): boolean {
        return this.audience?.notifyPortalVisit ?? false;
    }

    set audienceNotifyPortalVisit(p_value: boolean) {
        if (this.audience) {
            this.audience.notifyPortalVisit = p_value;
        }
    }

    private getAudiencePortalLink(preview: boolean): string {
        if (this.audience) {
            let link: string;
            if (this.audience.portalShortLinkID.length === 0 || preview) {
                link = `${AppModel.getInstance().global.storageUrl}/portal/index.html?apiUrl=${AppUserModel.getInstance().project.apiUrl}&portalToken=${this.audience.portalToken}`;
            } else {
                link = `${AppUserModel.getInstance().project.apiUrl}/sl/${this.audience.portalShortLinkID}`;
            }
            if (preview) {
                link += '&isPreview=1';
            }
            return link;
        }
        return '';
    }

    private _onShareClick(p_share: IEntityAudienceShareOutlineDto, p_e: Event) {
        this.selState.selected = p_share;
    }

    private _onPortalBtnClick() {
        if (this.audience) {
            this.$bvModal.show('preview-visitor-page-box');
        }
    }

    private _onCopyLinkBtnClick(p_e: Event) {
        // @ts-ignore
        this.$refs.portalLink.select();
        try {
            document.execCommand('copy');
            this.currentCopyToClipboardState = CopyToClipboardState.SUCCESS;
        } catch (err) {
            this.currentCopyToClipboardState = CopyToClipboardState.ERROR;
        }
        // @ts-ignore
        this.$refs.portalLink.blur();
    }

    private _onNotifyPortalVisitChange() {
        if (this.audience) {
            audienceController.updateConfig(this.audience);
        }
    }

}
</script>
