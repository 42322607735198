import i18n from '@/__plugins/i18n';
import {IDeepLinkParamsDto, IGlobalDto, ISplashDto} from "@/_model/app.dto";
import {LocalStorageKey, ServiceWorkerState} from "@/_model/app.constants";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import JsonUtil from "@/__libs/utility/JsonUtil";

class AppModel {

    public global!: IGlobalDto;
    public serverTime?: Date;
    public splash: ISplashDto = {
        bgUrl: 'img/init/init-bg.jpg',
        logoUrl: 'img/init/init-logo.png',
        welcomeMessage: i18n.t('InitWelcome') as string,
        aaUserNotFoundMessage: i18n.t('InitErrorCredentials') as string
    };

    public deepLinkParams: IDeepLinkParamsDto | null = null;

    public version: string | undefined = process.env.VERSION;

    // public updateAvailable: boolean = false;
    // public updateSkipped: boolean = false;

    public audienceOverviewOpen: boolean = true;
    public playlistOverviewOpen: boolean = false;

    private static _instance: AppModel;

    constructor() {
        if (!AppModel._instance) {
            AppModel._instance = this;
        }
        this.audienceOverviewOpen = !JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.AUDIENCE_LIST_COLLAPSED) as string);
        // this.playlistOverviewOpen = !JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.PLAYLIST_COLLAPSED) as string);
    }

    public static getInstance(): AppModel {
        if (!AppModel._instance) {
            new AppModel();
        }

        return AppModel._instance;
    }

    public toggleAudienceOverviewOpen() {
        this.audienceOverviewOpen = !this.audienceOverviewOpen;
        LocalStorageManager.storeValue(LocalStorageKey.AUDIENCE_LIST_COLLAPSED, JsonUtil.stringify(!this.audienceOverviewOpen));
    }

    public togglePlaylistOverviewOpen() {
        this.playlistOverviewOpen = !this.playlistOverviewOpen;
        LocalStorageManager.storeValue(LocalStorageKey.PLAYLIST_COLLAPSED, JsonUtil.stringify(!this.playlistOverviewOpen));
        if (this.playlistOverviewOpen) {
            this.audienceOverviewOpen = false;
        } else if (!JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.AUDIENCE_LIST_COLLAPSED) as string)) {
            this.audienceOverviewOpen = true;
        }
    }

}

export default AppModel;
