<template>
  <div class="list-item share-item" :class="{ active: isSelected }" @click="_onClick">

    <div class="list-item__icon">
      <div class="responsive aa-responsive--widescreen">
        <div class="responsive__wrapper">
          <AAImage :imageUrl="posterUrl"></AAImage>
        </div>
      </div>
    </div>

    <div class="list-item__info">
      <h6 class="title mb-2" v-line-clamp="2">{{ contentName }}</h6>
      <div>
        <div class="badge aa-content-item-type" :class="contentItemTypeClass">{{ contentItemTypeLabel }}</div>
      </div>
      <div class="badge badge-dark">{{ $d(shareDate) }}</div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import AudienceModel from "@/audience/_model/AudienceModel";
import fileManager from "@/_controller/FileManager";
import {IEntityAudienceShareOutlineDto} from "@/entity/_model/entity.dto";
import SelectionState from "@/__libs/_model/SelectionState";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AAImage from "@/_view/components/AAImage.vue";
import {EntityType} from "@/entity/_model/entity.constants";
import {FILE_TYPE_CONFIG, FileType} from "@/asset_folder/_model/asset_folder.constants";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileManager from "@/_controller/FileManager";
import FileUtil from "@/__libs/utility/FileUtil";
import {IAudienceFileDto} from "@/audience/_model/audience.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
    components: {
        AAImage
    }
})
export default class ShareOverviewItemRenderer extends Vue {

    @Prop() public share!: IEntityAudienceShareOutlineDto | IAudienceFileDto;
    @Prop() private selState!: SelectionState<IEntityAudienceShareOutlineDto | IAudienceFileDto>;

    get isEntity() {
        return (this.share as IEntityAudienceShareOutlineDto).entityType;
    }

    get isSelected() {
        return this.selState.selected === this.share;
    }

    get shareDate(): Date {
        if (this.isEntity) {
            return new Date((this.share as IEntityAudienceShareOutlineDto).shareDate);
        } else {
            return new Date((this.share as IAudienceFileDto).createdDate);
        }
    }

    get posterUrl() {
        if (this.isEntity) {
            const posterUri: string = languageManager.getTranslationForValue(
              (this.share as IEntityAudienceShareOutlineDto).poster,
              this.audienceLangCode
            );
            return fileManager.getFileUrl(posterUri);
        } else {
            const fileName: string = (this.share as IAudienceFileDto).fileName;
            const fileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(fileName));
            if (fileType === FileType.IMAGE) {
                return AppUserModel.getInstance().project.storageUrl + '/audiences/files/' + (this.share as IAudienceFileDto).fileCategoryType + '/' + fileName;
            } else {
                return FILE_TYPE_CONFIG[fileType].posterUrl;
            }
        }
    }

    get contentName() {
        if (this.isEntity) {
            return languageManager.getTranslationForValue(
              (this.share as IEntityAudienceShareOutlineDto).name,
              this.audienceLangCode
            );
        } else {
            return FileUtil.getFriendlyFileName((this.share as IAudienceFileDto).fileName);
        }
    }

    get audienceLangCode(): string | null {
        const activeAudience: AudienceModel | null = AudienceListModel.getInstance().globalSelState.selected;
        return (activeAudience) ? activeAudience.langCode : null;
    }

    get contentItemTypeClass() {
        if (this.isEntity) {
            return `aa-content-item-type--${(this.share as IEntityAudienceShareOutlineDto).entityType.toLowerCase()}`;

        } else {
            return `aa-content-item-type--content_file`;
        }
    }

    get contentItemTypeLabel(): string {
        if (this.isEntity) {
            return this.$t(`ContentItemTypes_${(this.share as IEntityAudienceShareOutlineDto).entityType}`) as string;

        } else {
            return this.$t(`ContentItemTypes_CONTENT_FILE`) as string;
        }
    }

    public _onClick(p_e: Event) {
        this.$emit("onClick", this.share);
    }
}
</script>
