<template>

  <div class="team-companies-detail">

    <div class="box box--full-height box--scrollable team-companies-detail-primary">

      <transition name="fade">
        <div v-if="!selState.selected"
             class="empty-message">
          {{ $t('PleaseSelectACompany') }}
        </div>
      </transition>

      <transition name="fade">
        <div v-if="company" :key="`${company.companyID}_primary`" class="box-wrapper">

          <div class="d-flex flex-column align-items-center text-center">
            <AAProfilePicture :name="company.displayName"
                              :imageUri="company.companyLogo"
                              :editable="true"
                              :big="true"
                              class="my-3"
                              v-on:click.native="_onOpenAssetPickerBtnClick"></AAProfilePicture>
            <h4 class="mb-5 title">{{ company.displayName }}</h4>
          </div>

          <b-form-group>
            <b-form-input size="lg"
                          :disabled="!isEditable"
                          v-model="validationCompany.displayName"
                          :placeholder="$t('PlaceholderDisplayName')"
                          :state="_calculateInputState($v.validationCompany.displayName)"
                          @input="company.displayName = _onFormValidationInput($v.validationCompany.displayName, company.displayName)"
                          tabindex="1"></b-form-input>
            <b-form-invalid-feedback>
                                <span class="d-block"
                                      v-if="!$v.validationCompany.displayName.required">{{
                                    $t('FieldRequiredSimple')
                                  }}</span>
              <span class="d-block"
                    v-if="!$v.validationCompany.displayName.minLength">{{ $t('MinimumFieldLengthSimple', [2]) }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

            <DataProviderEditor :dataProvider="company.dataDtp" :isStandalone="true" v-if="company.dataDtp" class="mb-3" />


        </div>


      </transition>

    </div>

    <div class="box box--full-height box--scrollable team-companies-detail-secondary">

      <transition name="fade">
        <div v-if="!selState.selected"
             class="empty-message">
          {{ $t('PleaseSelectACompany') }}
        </div>
      </transition>

      <transition name="fade">
        <div v-if="company" :key="`${company.companyID}_secondary`">

          <h4 class="title mt-1 mb-4">{{ $t('CompanyDetails') }}</h4>

          <table class="table table-sm table-striped table-borderless">
            <tbody>
            <tr>
              <th scope="row">{{ $t('CreatedDate') }}</th>
              <td>{{ $d(new Date(company.createdDate), 'short') }}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </transition>


    </div>

    <b-modal v-if="company" id="app-user-asset-picker-box" size="xl" :title="$t('PickCompanyAvatarOrUploadNewOne')"
             hide-footer>
      <AssetFoldersPicker :selectedFileUri="company.companyLogo"
                          :allowedTargetType="avatarAllowedTargetType"
                          :allowedFileTypes="avatarAllowedFileTypes"
                          v-on:onAssetFilePicked="_onAssetFilePicked"></AssetFoldersPicker>
    </b-modal>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import SyncWidget from "@/sync/_view/SyncView.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import {minLength, required} from "vuelidate/lib/validators";
import SelectionState from "@/__libs/_model/SelectionState";
import CompanyModel from "@/company/_model/CompanyModel";
import companyController from "@/company/_controller/CompanyController";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import DataProviderEditor from "@/data_tool/data_provider/_view/DataProviderEditor.vue";


@Component({
  components: {
    AAProfilePicture, AssetFoldersPicker, SyncWidget, DataProviderEditor
  },
  validations: {
    validationCompany: {
      displayName: {
        required,
        minLength: minLength(2)
      }
    }
  }
})
export default class CompanyDetail extends Vue {

  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() private selState!: SelectionState<CompanyModel>;

  //---------------------------------
  // Vue Component data
  //---------------------------------

  public avatarLoaded: Boolean = false;

  public avatarAllowedTargetType: FileTargetType = FileTargetType.AUDIENCE_AVATAR;
  public avatarAllowedFileTypes: FileType[] = [FileType.IMAGE];

  private validationCompany: CompanyModel = new CompanyModel();


  //---------------------------------
  // Vue Computed properties
  //---------------------------------


  get hasChanges() {
    return this.company && this.company.hasChanges;
  }


  get company() {
    return this.selState.selected;
  }


  get isEditable(): boolean {
    return this.company!.rightActions.indexOf(RightAction.EDIT) >= 0;
  }


  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  mounted() {
    // this._createValidationCompany();
    window.addEventListener('beforeunload', this._beforeUnload);
  }

  beforeDestroy() {
    if (this.company && this.company.hasChanges) {
      this._saveCompany(this.company);
    }

    window.removeEventListener('beforeunload', this._beforeUnload)
  }

  //---------------------------------
  // Private / helper methods
  //---------------------------------

  private _calculateInputState(inputValidator: any): any {
    return inputValidator.$invalid ? false : null;
  }

  private _beforeUnload(e: BeforeUnloadEvent) {
    //this._saveUser();
    // e.returnValue = "Are you sure you want to leave, changes you made will not be saved.";
    // return e.returnValue;
  }

  private _createValidationCompany(p_company: CompanyModel): void {
    if (this.company) {
      this.validationCompany = new CompanyModel();
      this.validationCompany.displayName = p_company.displayName;
    }
  }

  private _onFormValidationInput(inputValidator: any, value: any): void {
    if (!inputValidator.$invalid && this.company) {
      value = inputValidator.$model;
      this._onFormInput(null);
    }
    return value;
  }

  private _onFormInput(p_e: Event | null) {
    this.company!.hasChanges = true;
  }


  private _saveCompany(p_company: CompanyModel) {
    companyController.saveBody(p_company);
  }


  private _onOpenAssetPickerBtnClick(p_e: Event) {
    this.$bvModal.show('app-user-asset-picker-box');
  }

  private _onAssetFilePicked(p_assetFile: AssetFileModel) {
    this.$bvModal.hide('app-user-asset-picker-box');
    if (this.isEditable && p_assetFile && this.company) {
      this.company.companyLogo = p_assetFile.fileURI;
      this.company.hasChanges = true;
    }
  }

  @Watch('company', {immediate: true, deep: false})
  private _onActiveCompanyChange(newCompany: CompanyModel | null, oldCompany: CompanyModel | null) {
    if (oldCompany && oldCompany.hasChanges) {
      this._saveCompany(oldCompany);
    }
    if (newCompany) {
      this._createValidationCompany(newCompany);
    }
  }

  private async _onDeleteUserBtnClick(p_e: Event) {
    const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t("SureDeleteCompany") as string, {centered: true});

    if (deleteConfirmed) {
      //const isDeleted:boolean = await companyController.deleteCompany(this.company!);
    }
  }

  private async _onImpersonateUserBtnClick(p_e: Event) {

  }

}
</script>

