<template>
  <div class="world" id="app" :class="{ 'audience-overview-open': audienceOverviewOpen, 'playlist-overview-open': playlistOverviewOpen, 'playlist-entry-overview-open': playlistEntryOverviewOpen }">

    <!-- Main pages !-->
    <transition appear name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <!-- Toasts !-->
    <ToastHolder></ToastHolder>

    <!-- Hints !-->
    <HintHolder></HintHolder>

    <!-- Min width keeper !-->
    <MinWidthKeeper></MinWidthKeeper>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ToastHolder from "@/_view/components/ToastHolder.vue";
import HintHolder from "@/help/hint/_view/HintHolder.vue";
import MinWidthKeeper from "@/_view/components/MinWidthKeeper.vue";
import UpdateBox from "@/_view/components/UpdateBox.vue";
import AppModel from "@/_model/AppModel";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";

@Component({
    components: {
        UpdateBox,
        MinWidthKeeper,
        HintHolder,
        ToastHolder
    },
})

export default class App extends Vue {

    public appModel: AppModel = AppModel.getInstance();
    public playlistListModel: PlaylistListModel = PlaylistListModel.getInstance();

    private timeout: any = null;

    private appHeight = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        }, 500);
    }

    public mounted() {
        window.addEventListener('resize', this.appHeight);
        window.addEventListener('orientationchange', this.appHeight);
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }

    get audienceOverviewOpen(): boolean {
        return this.appModel.audienceOverviewOpen;
    }

    get playlistOverviewOpen(): boolean {
        return this.appModel.playlistOverviewOpen;
    }

    get playlistEntryOverviewOpen(): boolean {
        return !this.playlistListModel.playlistPlayerCollapsed;
    }

}
</script>

<style lang="scss">
@import '@/__app_assets/scss/styles';
</style>
