<template>
  <div class="player content-file-player">

    <div v-if="onlyRunsOnMobile">
      {{ $t('FileCanOnlyRunOnMobile') }}
    </div>
    <div v-else>
      <div v-if="showInPlayer">
        <PDFViewer
            v-if="contentFileIsPDF"
            :engMode="engineMode"
            :pdfURL="fileManager.getFileUrl(contentFile.fileUri)"
            @pageViewed="handlePageViewed"/>

        <iframe v-if="!contentFileIsPDF" :src="frameUrl" class="player__frame" ref="playerFrame" v-on:load="_onIframeLoad()"></iframe>

      </div>
      <div v-else class="content-file-player__button-wrapper">
        <b-button variant="primary" @click="_downloadFile"
                  v-html="$t('DownloadFile')"></b-button>
      </div>
    </div>

    <div class="player__back" @click="_onBackButtonClick">
      <div class="player__back__background"></div>
      <i class="fas fa-chevron-left"></i>
    </div>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import ContentListModel from "@/content/_model/ContentListModel";
import {EngineMessageType, EngineMode} from "@/presentation/player/_model/player.constants";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import {IEngineMessageDto} from "@/presentation/player/_model/player.dto";
import audienceController from "@/audience/_controller/AudienceController";
import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
import {IAudienceSessionEventDto} from "@/audience/session/_model/audience_session.dto";
import contentController from "@/content/_controller/ContentController";
import {RoutingIdentifier} from "@/router";
import fileManager from "@/_controller/FileManager";
import {FILE_TYPE_CONFIG, FileType, IFileTypeConfig} from '@/asset_folder/_model/asset_folder.constants';
import PDFViewer from "../../../_view/components/PDFViewer.vue";
import {SessionMode} from "@/audience/session/_model/audience_session.constants";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import EntityModel from "@/entity/_model/EntityModel";
import {GamificationTargetIdentifier} from "@/gamification/_model/gamification.constants";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";

@Component({
    components: {PDFViewer}
})
export default class ContentFilePlayer extends Vue {

    @Prop() private engineMode!: EngineMode;
    @Prop() private contentFileID!: string;
    @Prop() private sessionIdentifier!: string;
    @Prop() private inPlaylistMode?: boolean;

    private shareMessage: string = "";

    private parentSessionIdentifier?: string;

    private fileType!: FileType;
    private fileTypeConfig!: IFileTypeConfig;
    private onlyRunsOnMobile: boolean = false;
    private showInPlayer: boolean = false;

    private contentFileTypes = FileType;
    private fileManager = fileManager;

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get contentFile(): ContentFileModel | null {
        if (this.contentFileID) {
            return ContentListModel.getInstance().getEntityByID(this.contentFileID) as ContentFileModel;
        }
        return null;
    }

    get contentFileIsPDF(): null | boolean {

        return this.fileType === this.contentFileTypes.PORTABLE_DOCUMENT;
    }

    get frameUrl(){
        return fileManager.getFileUrl(this.contentFile!.fileUri);
    }

    public mounted() {
        this.fileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(this.contentFile!.fileUri));
        this.fileTypeConfig = FILE_TYPE_CONFIG[this.fileType];
        this.onlyRunsOnMobile = this.fileTypeConfig.isMobileOnly && !this.$device.ios && !this.$device.android;
        this.showInPlayer = this.fileTypeConfig.showInPlayer;

        if (!this.onlyRunsOnMobile) {
            if (this.engineMode === EngineMode.PRESENT) {
                this.parentSessionIdentifier = audienceSessionController.createAudienceSession(this.contentFile as EntityModel, this.audience!, SessionMode.PRESENT, ActivityType.FILE_SESSION, undefined, undefined, undefined, {fileType: this.fileType}, GamificationTargetIdentifier.PRESENT_CONTENT);
            }
            if (this.showInPlayer) {
                window.addEventListener('message', this.listenToEngine);
                window.addEventListener('beforeunload', this._beforeUnload);
            } else {
                this._downloadFile();
            }
        }
    }

    private _downloadFile() {
        window.open(fileManager.getFileUrl(this.contentFile!.fileUri), "_blank");
    }

    public beforeDestroy() {
        window.removeEventListener('message', this.listenToEngine);
        window.removeEventListener('beforeunload', this._beforeUnload);
    }



    private _onIframeLoad() {
        const playerFrame: HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
        // @ts-ignore;
        playerFrame.contentWindow.focus();
    }

    private async listenToEngine(p_messageEvent: MessageEvent) {
        //todo: check origin

        const message: IEngineMessageDto = p_messageEvent.data;
        if (message.messageType) {

            switch (message.messageType) {
                case  EngineMessageType.ASK_PATCH_AUDIENCE:
                    audienceController.patchBody(this.audience!, message.messageValue);
                    break;

                case  EngineMessageType.REGISTER_AUDIENCE_SESSION:
                    audienceSessionController.registerAudienceSession(message.messageValue);
                    break;

                case  EngineMessageType.REGISTER_AUDIENCE_SESSION_EVENT:
                    (message.messageValue as IAudienceSessionEventDto).audienceID = this.audience!.ID;
                    (message.messageValue as IAudienceSessionEventDto).parentSessionIdentifier = this.parentSessionIdentifier as string;
                    audienceSessionController.registerAudienceSessionEvent(message.messageValue);
                    break;

                case  EngineMessageType.ASK_SAVE:
                    if (this.contentFile) {
                        contentController.saveBody(this.contentFile, message.messageValue);
                    }
                    break;

                case  EngineMessageType.ASK_SHARE_WITH_AUDIENCE:
                    if (this.contentFile) {
                        this.shareMessage = message.messageValue.shareMessage;
                        this.$bvModal.show('share-with-audience-box');
                    }
                    break;

                case  EngineMessageType.ON_NAVIGATION_BOUNDRY:
                    if (this.inPlaylistMode) {
                        PlaylistListModel.getInstance().togglePlaylistPlayer(false);
                    }
                    break;
            }
        } else {
            return;
        }
    }

    private _beforeUnload(e: BeforeUnloadEvent) {

        // e.returnValue = "Are you sure you want to leave, changes you made will not be saved.";
        // return e.returnValue;
    }

    private _onShareWithAudienceFinish() {
        this.$bvModal.hide('share-with-audience-box');
    }

    private async _onBackButtonClick() {
        contentController.stopRemotePresent();
        await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
    }

    private handlePageViewed(p_pageNumber: number, secondsViewed: number) {
        if (this.contentFile && this.audience && this.parentSessionIdentifier && this.engineMode === EngineMode.PRESENT) {
            audienceSessionController.createAudienceSession(this.contentFile, this.audience, SessionMode.PRESENT, ActivityType.PAGE_SESSION, p_pageNumber.toString(), secondsViewed, this.parentSessionIdentifier);
        }
    }

}
</script>
