<template>
  <div class="sub-page app-user-page">

    <AppUserSummary></AppUserSummary>
    <AppUserDetail></AppUserDetail>
    <SyncWidget></SyncWidget>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AppUserSummary from "@/project/user/_view/AppUserSummary.vue";
import AppUserDetail from "@/project/user/_view/AppUserDetail.vue";
import SyncWidget from "@/sync/_view/SyncView.vue";

@Component({
  components: {
    SyncWidget,
    AppUserDetail,
    AppUserSummary
  }
})
export default class AppUserPage extends Vue {

}
</script>

