<template>
  <div class="box box--full-height list-overview activity-overview">

    <div class="list-overview-filters mb-0">
      <date-picker v-model="filterDate" type="month" @input="onChanged" :clearable="true"></date-picker>
      <b-form-select v-model="actionType" @input="onChanged">
        <b-form-select-option v-for="actionTypeFilter in availableActivityActionTypeFilters" :value="actionTypeFilter"
                              :key="actionTypeFilter">{{ $t(`ActivityActionTypeFilter_${actionTypeFilter}`) }}
        </b-form-select-option>
      </b-form-select>
    </div>

    <div class="list-overview-items">
      <transition name="fade">
        <div class="list-overview-items__wrapper" v-if="activities.length > 0">
          <ActivityOverviewItemRenderer
              v-for="activity in activities"
              :activity="activity"
              :key="activity.identifier"
              v-on:onClick="_onActivityClick"
              :selState="selState"
          />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="activities.length === 0" class="empty-message">
          {{ $t('TrackPageActivityNoActivitiesForFilters') }}
        </div>
      </transition>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {IAudienceActivityDto} from "@/audience/track/_model/audience_activity.dto";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import SelectionState from "@/__libs/_model/SelectionState";
import ActivityOverviewItemRenderer from "@/audience/track/_view/ActivityOverviewItemRenderer.vue";
import {
    ActivityActionTypeFilter,
    ActivityCategoryType,
    ActivityType
} from "@/audience/track/_model/audience_activity.constants";
import DatePicker from "vue2-datepicker";

@Component({
    components: {ActivityOverviewItemRenderer, DatePicker}
})
export default class ActivityOverview extends Vue {

    @Prop() public selState!: SelectionState<IAudienceActivityDto>;

    public availableActivityActionTypeFilters: ActivityActionTypeFilter[] = [ActivityActionTypeFilter.VIEWED, ActivityActionTypeFilter.PRESENTED, ActivityActionTypeFilter.VISITED, ActivityActionTypeFilter.ALL];
    public actionType: ActivityActionTypeFilter = ActivityActionTypeFilter.ALL;
    public filterDate: Date | null = null;

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get activities(): IAudienceActivityDto[] {
        if (this.audience) {
            // we return the list of activities reversed, newest first
            let activities: IAudienceActivityDto[] = this.audience.activities.sort((a, b) => {
                return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
            });
            // filter month
            activities = activities.filter((p_activity: IAudienceActivityDto) => {
                if (this.filterDate) {
                    const activityDate = new Date(p_activity.startDate);
                    return activityDate.getFullYear() === this.filterDate.getFullYear() && activityDate.getMonth() === this.filterDate.getMonth();
                }
                return true;
            });
            // filter on actionType
            if (this.actionType === ActivityActionTypeFilter.VIEWED) {
                activities = activities.filter((p_activity: IAudienceActivityDto) => {
                    if (p_activity.activityType === ActivityType.PRESENTATION_SESSION || p_activity.activityType === ActivityType.APP_SESSION || p_activity.activityType === ActivityType.FILE_SESSION || p_activity.activityType === ActivityType.AUDIENCE_FILE_SESSION) {
                        if (p_activity.categoryType !== ActivityCategoryType.MEETING) {
                            return true;
                        }
                    }
                    return false;
                });
            } else if (this.actionType === ActivityActionTypeFilter.PRESENTED) {
                activities = activities.filter((p_activity: IAudienceActivityDto) => {
                    if (p_activity.activityType === ActivityType.PRESENTATION_SESSION || p_activity.activityType === ActivityType.APP_SESSION || p_activity.activityType === ActivityType.FILE_SESSION || p_activity.activityType === ActivityType.AUDIENCE_FILE_SESSION) {
                        if (p_activity.categoryType === ActivityCategoryType.MEETING) {
                            return true;
                        }
                    }
                    return false;
                });
            } else if (this.actionType === ActivityActionTypeFilter.VISITED) {
                activities = activities.filter((p_activity: IAudienceActivityDto) => {
                    return p_activity.activityType === ActivityType.PORTAL_SESSION;
                });
            }
            //
            return activities;
        }
        return [];
    }

    public async onChanged() {
        if (this.activities.length > 0) {
            if (!this.selState.selected || !this.activities.includes(this.selState.selected)) {
                this.selState.selected = this.activities[0];
            }
        } else {
            this.selState.selected = null;
        }
    }

    public mounted() {
        this.onChanged();
    }

    public _onActivityClick(p_activity: IAudienceActivityDto, p_e: Event) {
        this.selState.selected = p_activity;
    }

}
</script>
