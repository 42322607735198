<template>
  <div class="entity-action-box folder-action-box text-center">
    <b-button block variant="primary"
              @click="_onShareWithAudiencesBtnClick">
      <i class="fas fa-external-link-alt fa-lg"></i>{{ $t('EntityActionBoxShare') }}
    </b-button>
    <b-button v-if="showEdit" block variant="outline-secondary" @click="_onEditContentBtnClick">
      <i class="fas fa-pen-to-square fa-lg"></i>{{ $t('DashboardShowEditButton') }}
    </b-button>
    <b-button v-if="showDelete" block variant="outline-secondary" @click="_onDeleteFolderBtnClick">
      <i class="fas fa-trash-can fa-lg"></i>{{ $t('DashboardShowDeleteButton') }}
    </b-button>
    <b-button v-if="showEdit" block variant="outline-secondary" @click="_onShareWithTeamsBtnClick">
      <i class="fas fa-people-group fa-lg"></i>{{ $t('DashboardShowShareWithTeamButton') }}
    </b-button>
    <b-button block variant="outline-secondary" @click="_onInfoBtnClick">
      <i class="fas fa-circle-info fa-lg"></i>{{ $t('Info') }}
    </b-button>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import contentFolderController from "@/content/_controller/ContentFolderController";

@Component({
    components: {}
})
export default class EntityActionBox extends Vue {

    @Prop() public contentFolder!: ContentFolderModel;

    get showShareWithAudience(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.SHARE_ENTITIES_WITH_AUDIENCES.identifier) >= 0;
    }

    get showShareWithMultipleAudiences(): boolean {
        const globalSelState: EntitySelectionState<AudienceModel> = AudienceListModel.getInstance().globalSelState;
        const shareWithMultiple: boolean = globalSelState.hasMultipleSelected && globalSelState.selecteds.length > 0;
        return this.showShareWithAudience && shareWithMultiple;
    }

    get showEdit(): boolean {
        return this.contentFolder.isEditable;
    }

    get showDelete(): boolean {
        return this.contentFolder.isEditable;
    }

    private _onInfoBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_SHOW_FOLDER_INFO, this.contentFolder);
    }

    private _onShareWithAudiencesBtnClick(p_e: Event) {
        if (this.showShareWithMultipleAudiences) {
            EventBus.$emit(EventBusActions.ASK_SHARE_FOLDER_WITH_AUDIENCES, this.contentFolder);

        } else {
            EventBus.$emit(EventBusActions.ASK_SHARE_FOLDER_WITH_AUDIENCE, this.contentFolder);

        }
    }

    private _onShareWithTeamsBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_SHARE_FOLDER_WITH_TEAMS, this.contentFolder);
    }

    private _onEditContentBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_CREATE_EDIT_CONTENT_FOLDER, this.contentFolder);
    }

    private async _onDeleteFolderBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_DELETE_FOLDER, this.contentFolder);
    }

}
</script>
