<template>
  <div class="content-overview">

    <PlaylistsOverview></PlaylistsOverview>

    <div class="content-overview-quick-launch" v-if="quickLaunchContents.length > 0">
      <ContentQuickLaunchItemRenderer
          v-for="content in quickLaunchContents"
          :content="content"
          :key="content.ID"
      />
    </div>

    <div class="content-overview-filters">
      <b-input-group>
        <template #prepend>
          <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
        </template>
        <b-form-input v-model="selState.searchFilter"
                      :placeholder="$t('DashboardShowFilterbar')" @input="_onSearchInput"></b-form-input>
      </b-input-group>

      <b-dropdown variant="link" right class="content-order-button" no-caret v-b-tooltip.hover.top
                  :title="$t('ToolTipOrderContent')">
        <template #button-content>
          <i class="fas fa-sort-alpha-down fa-lg"></i>
        </template>
        <b-dropdown-item v-for="sortingType in availableSortingTypes" :key="sortingType"
                         @click="selState.activeSorting = sortingType">
          <span>{{ $t(`sortBy_${sortingType}`) }}</span>
        </b-dropdown-item>
      </b-dropdown>

      <div class="filter-button available" @click="inFunnelStageMode = !inFunnelStageMode" v-if="funnelStagesAvailable"
           :class="{ available: true, active: inFunnelStageMode }" v-b-tooltip.hover.top
           :title="$t('ToolTipFunnelStage')">
        <i class="fas fa-tasks fa-lg"></i>
      </div>

      <div class="filter-button available" @click="inLikedMode = !inLikedMode"
           :class="{ available: true, active: inLikedMode }" v-b-tooltip.hover.top
           :title="$t('ToolTipLiked')">
        <i class="fas fa-heart fa-lg"></i>
      </div>

      <b-button variant="success" class="playlist-button" @click="_togglePlayListOverview"
                v-html="$t('Playlists')"></b-button>

      <b-dropdown :text="$t('DashboardShowNewButton')" variant="secondary" right class="new-button"
                  :id="hintIds.HOME_CREATE_CONTENT" :disabled="!online">
        <b-dropdown-item v-if="showAddPresentation" @click="_onAddPresentationBtnClick">
          <i class="fas fa-chalkboard"></i>
          <span>{{ $t('DashboardShowNewButtonPresentation') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider v-if="showAddContentFile"></b-dropdown-divider>
        <b-dropdown-item v-if="showAddContentFile" @click="_onAddFileBtnClick">
          <i class="far fa-file-pdf"></i>
          <span>{{ $t('DashboardShowNewButtonFile') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider v-if="showAddContentApp"></b-dropdown-divider>
        <b-dropdown-item v-if="showAddContentApp" @click="_onAddAppBtnClick">
          <i class="far fa-window-maximize"></i>
          <span>{{ $t('DashboardShowNewButtonApp') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider v-if="showAddContentFolder"></b-dropdown-divider>
        <b-dropdown-item v-if="showAddContentFolder" @click="_onAddFolderBtnClick">
          <i class="far fa-folder"></i>
          <span>{{ $t('DashboardShowNewButtonFolder') }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="content-overview-breadcrumbs"
         v-if="!inFunnelStageMode && !searchMode && !inLikedMode && contentFolderListModel.rootContentFolder.childFolders.length > 0 && contentFolderListModel.breadCrumbs.length > 1">
      <ContentBreadCrumbItemRenderer
          v-for="contentFolder in contentFolderListModel.breadCrumbs"
          :contentFolder="contentFolder"
          :key="contentFolder.folderID"
          v-on:onClick="_onContentFolderClick"
      />
    </div>

    <!-- search liked mode-->
    <div class="content-overview-items" v-if="searchMode || inLikedMode">
      <div class="content-overview-items__priority" v-if="filteredPriorityContents.length > 0">
        <ContentOverviewItemRenderer
            v-for="content in filteredPriorityContents"
            :content="content"
            :key="content.ID"
            v-on:onClick="_onContentClick"
            v-on:onLikeChanged="_onContentLikeChanged"
            :selState="selState"
            parentReference="overview"
        />
      </div>
      <div class="content-overview-items__normal">
        <ContentOverviewItemRenderer
            v-for="content in filteredNormalContents"
            :content="content"
            :key="content.ID"
            v-on:onClick="_onContentClick"
            v-on:onLikeChanged="_onContentLikeChanged"
            :selState="selState"
            :ref="content.ID"
            parentReference="overview"
        />
      </div>
    </div>

    <!-- funnel stage mode-->
    <transition name="fade">
      <div v-if="inFunnelStageMode && !searchMode && !inLikedMode" class="content-overview-funnel-stage">

        <div class="content-overview-funnel-stage__header">
          <h4 class="title mb-0">{{ $t('FunnelStages') }}</h4>
          <b-form-group class="mb-0">
            <b-form-select v-model="shownFunnelStageRef" tabindex="2">
              <option v-for="funnelStage in allFunnelStages" :key="funnelStage.reference"
                      :value="funnelStage.reference">{{ getFunnelStageName(funnelStage) }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>

        <FunnelStageItemRenderer v-for="funnelStage in shownFunnelStages" :key="funnelStage.reference"
                                 :funnelStage="funnelStage"
                                 :selState="selState"
        />

      </div>
    </transition>

    <!-- normal mode-->
    <div class="content-overview-items" v-if="!inFunnelStageMode && !searchMode && !inLikedMode"
         :id="hintIds.HOME_CONTENT_OVERVIEW">
      <div class="content-overview-items__priority" v-if="priorityFolderContents.length > 0">
        <ContentOverviewItemRenderer
            v-for="content in priorityFolderContents"
            :content="content"
            :key="content.ID"
            v-on:onClick="_onContentClick"
            :selState="selState"
            parentReference="overview"
        />
      </div>
      <div class="content-overview-items__normal">
        <ContentFolderItemRenderer
            v-for="contentFolder in childFolders"
            :contentFolder="contentFolder"
            :key="contentFolder.folderID"
            v-on:onClick="_onContentFolderClick"
            :selState="selState"
            v-on:onClickDots="_onContentFolderClickDots"
        />
        <ContentOverviewItemRenderer
            v-for="content in normalFolderContents"
            :content="content"
            :key="content.ID"
            v-on:onClick="_onContentClick"
            :selState="selState"
            :ref="content.ID"
            parentReference="overview"
        />
      </div>
    </div>

    <b-modal id="create-content-box" size="lg" :title="createContentTitle" hide-footer>
      <CreateContentBox v-on:onFinish="_onCreateContentFinish"
                        :createContentType="createContentType"></CreateContentBox>
    </b-modal>

    <b-popover custom-class="hint-popover" :target="hintIds.HOME_CREATE_CONTENT"
               placement="right"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.HOME_CREATE_CONTENT"></Hint>
    </b-popover>
    <b-popover custom-class="hint-popover" :target="hintIds.HOME_CONTENT_OVERVIEW"
               placement="top"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.HOME_CONTENT_OVERVIEW"></Hint>
    </b-popover>

  </div>
</template>


<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import ContentOverviewItemRenderer from "@/content/_view/ContentOverviewItemRenderer.vue";
import ContentModel from "@/content/_model/ContentModel";
import contentListController from "@/content/_controller/ContentListController";
import ContentListModel from "@/content/_model/ContentListModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import ContentQuickLaunchItemRenderer from "@/content/_view/ContentQuickLaunchItemRenderer.vue";
import {EntityType, LoadingStatus} from "@/entity/_model/entity.constants";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions, SortingType} from "@/_model/app.constants";
import CreateContentBox from "@/content/_view/CreateContentBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import ContentFolderItemRenderer from "@/content/_view/ContentFolderItemRenderer.vue";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import ContentBreadCrumbItemRenderer from "@/content/_view/ContentBreadCrumbItemRenderer.vue";
import FunnelStageItemRenderer from "@/content/_view/FunnelStageItemRenderer.vue";
import ContentOverviewSelectionState from "@/content/_model/ContentOverviewSelectionState";
import {IProjectFunnelStageDto} from "@/project/_model/project.dto";
import HintListController from "@/help/hint/_controller/HintListController";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import NetworkManager from "@/_controller/NetworkManager";
import * as vm from "vm";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import PlaylistsOverview from "@/playlist/_view/PlaylistsOverview.vue";
import AppModel from "@/_model/AppModel";

@Component({
    components: {
        ContentBreadCrumbItemRenderer,
        ContentQuickLaunchItemRenderer,
        ContentOverviewItemRenderer,
        ContentFolderItemRenderer,
        CreateContentBox,
        FunnelStageItemRenderer,
        Hint,
        PlaylistsOverview
    }
})
export default class ContentOverview extends Vue {

    public contentListModel: ContentListModel = ContentListModel.getInstance();
    public appModel: AppModel = AppModel.getInstance();
    private hintIds: typeof HintIds = HintIds;
    public selState: ContentOverviewSelectionState = ContentListModel.getInstance().globalSelState;
    public contentFolderListModel: ContentFolderListModel = ContentFolderListModel.getInstance();
    private createContentType: EntityType = EntityType.PRESENTATION;
    private createContentTitle: string = "";
    private availableSortingTypes: SortingType[] = [SortingType.NEWEST_FIRST, SortingType.OLDEST_FIRST, SortingType.NAME_AZ, SortingType.NAME_ZA];
    private static ALL_STAGES: string = "ALL_STAGES";
    private funnelStagesAvailable: boolean = AppUserModel.getInstance().project.config.funnelStages.length > 0;
    private inLikedMode: boolean = false;
    private inFunnelStageMode: boolean = false;
    private allFunnelStages: IProjectFunnelStageDto[] = [{reference: ContentOverview.ALL_STAGES}, ...AppUserModel.getInstance().project.config.funnelStages];
    private shownFunnelStageRef: string = ContentOverview.ALL_STAGES;
    public networkManager: NetworkManager = NetworkManager.getInstance();
    public playlistListModel: PlaylistListModel = PlaylistListModel.getInstance();

    get contents(): ContentModel[] {
        return this.contentListModel.list;
    }

    get online() {
        return this.networkManager.online;
    }

    get searchMode(): boolean {
        return this.selState.searchFilter.length > 1
    }

    get showAddPresentation(): boolean {
        return (AppUserModel.getInstance().rights.indexOf(RIGHTS.CREATE_PRESENTATIONS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasPresentations);
    }

    get showAddContentFile(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.CREATE_CONTENT_FILES.identifier) >= 0;
    }

    get showAddContentApp(): boolean {
        return (AppUserModel.getInstance().rights.indexOf(RIGHTS.CREATE_CONTENT_APPS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasContentApps);
    }

    get showAddContentFolder(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.CREATE_PERSONAL_FOLDERS.identifier) >= 0;
    }

    get filteredNormalContents() {
        let filtered: ContentModel[] = this.normalContents;
        if (this.inLikedMode) {
            filtered = filtered.filter(content => {
                return contentListController.contentIsLiked(content);
            });
        }
        if (this.selState.searchFilter.length > 1) {
            filtered = filtered.filter(content => {
                return (
                  languageManager
                    .getTranslationForValue<string>(content.name, this.audienceLangCode)
                    .toLowerCase()
                    .indexOf(this.selState.searchFilter.toLowerCase()) > -1
                );
            });
        }
        return this._sortContent(filtered);
    }

    get filteredPriorityContents() {
        let filtered: ContentModel[] = this.priorityContents;
        if (this.inLikedMode) {
            filtered = filtered.filter(content => {
                return contentListController.contentIsLiked(content);
            });
        }
        if (this.selState.searchFilter.length > 1) {
            filtered = filtered.filter(content => {
                return (
                  languageManager
                    .getTranslationForValue<string>(content.name, this.audienceLangCode)
                    .toLowerCase()
                    .indexOf(this.selState.searchFilter.toLowerCase()) > -1
                );
            });
        }
        return this._sortContent(filtered)
    }

    get shownFunnelStages() {
        const shownFunnelStages: IProjectFunnelStageDto[] = AppUserModel.getInstance().project.config.funnelStages;
        if (this.shownFunnelStageRef === ContentOverview.ALL_STAGES) {
            return shownFunnelStages;
        } else {
            return shownFunnelStages.filter(fs => {
                return fs.reference === this.shownFunnelStageRef;
            });
        }
    }

    get normalContents() {
        const filtered = this.contents.filter(content => {
            return content.priority == 0;
        });
        return this._sortContent(filtered);
    }

    get priorityContents() {
        const filtered = this.contents.filter(content => {
            return content.priority > 0;
        });
        return this._sortContent(filtered);
    }


    get normalFolderContents() {
        const filtered = this.contentFolderListModel.activeContentFolder.childContents.filter(content => {
            return content.priority == 0;
        });
        return this._sortContent(filtered);
    }

    get priorityFolderContents() {
        const filtered = this.contentFolderListModel.activeContentFolder.childContents.filter(content => {
            return content.priority > 0;
        });
        return this._sortContent(filtered);
    }

    get childFolders() {
        return this._sortFolders(this.contentFolderListModel.activeContentFolder.childFolders)
    }


    get audienceLangCode(): string | null {
        const activeAudience: AudienceModel | null = AudienceListModel.getInstance().globalSelState.selected;
        return (activeAudience) ? activeAudience.langCode : null;
    }

    get quickLaunchContents() {
        const filtered = this.contents.filter(content => {
            return content.isQuickLaunch;
        });
        return this._sortContent(filtered);
    }

    get playlistOverviewCollapsed() {
        return this.playlistListModel.playlistOverviewCollapsed;
    }


    public mounted() {
        contentListController.fetchAllEntities();
        // hints
        HintListController.startGroup(HintGroupIds.HOME);
    }


    private getFunnelStageName(p_funnelStage: IProjectFunnelStageDto) {
        if (p_funnelStage.reference === ContentOverview.ALL_STAGES) {
            return this.$t('AllFunnelStages');
        } else {
            return languageManager.getTranslationForValue<string>(p_funnelStage.name!, AppUserModel.getInstance().langCode)
        }
    }

    private _sortFolders(p_folders: ContentFolderModel[]) {
        if (this.selState.activeSorting === SortingType.NEWEST_FIRST) {
            return p_folders.sort((a: ContentFolderModel, b: ContentFolderModel) => {
                if (a.createdDate > b.createdDate) {
                    return -1;
                }
                if (a.createdDate < b.createdDate) {
                    return 1;
                }
                return 0;
            });
        } else if (this.selState.activeSorting === SortingType.OLDEST_FIRST) {
            return p_folders.sort((a: ContentFolderModel, b: ContentFolderModel) => {
                if (b.createdDate > a.createdDate) {
                    return -1;
                }
                if (b.createdDate < a.createdDate) {
                    return 1;
                }
                return 0;
            });
        } else if (this.selState.activeSorting === SortingType.NAME_AZ) {
            return p_folders.sort((a: ContentFolderModel, b: ContentFolderModel) => {
                const aName: string = languageManager.getTranslationForValue(a.body.name, this.audienceLangCode).toLowerCase();
                const bName: string = languageManager.getTranslationForValue(b.body.name, this.audienceLangCode).toLowerCase();
                if (aName > bName) {
                    return 1;
                }
                if (aName < bName) {
                    return -1;
                }
                return 0;
            });
        } else if (this.selState.activeSorting === SortingType.NAME_ZA) {
            return p_folders.sort((a: ContentFolderModel, b: ContentFolderModel) => {
                const aName: string = languageManager.getTranslationForValue(a.body.name, this.audienceLangCode).toLowerCase();
                const bName: string = languageManager.getTranslationForValue(b.body.name, this.audienceLangCode).toLowerCase();
                if (aName > bName) {
                    return -1;
                }
                if (aName < bName) {
                    return 1;
                }
                return 0;
            });
        }
        return p_folders;
    }

    private _sortContent(p_contents: ContentModel[]) {
        if (this.selState.activeSorting === SortingType.NEWEST_FIRST) {
            return p_contents.sort((a: ContentModel, b: ContentModel) => {
                if (a.createdDate > b.createdDate) {
                    return -1;
                }
                if (a.createdDate < b.createdDate) {
                    return 1;
                }
                return 0;
            });
        } else if (this.selState.activeSorting === SortingType.OLDEST_FIRST) {
            return p_contents.sort((a: ContentModel, b: ContentModel) => {
                if (b.createdDate > a.createdDate) {
                    return -1;
                }
                if (b.createdDate < a.createdDate) {
                    return 1;
                }
                return 0;
            });
        } else if (this.selState.activeSorting === SortingType.NAME_AZ) {
            return p_contents.sort((a: ContentModel, b: ContentModel) => {
                const aName: string = languageManager.getTranslationForValue(a.name, this.audienceLangCode);
                const bName: string = languageManager.getTranslationForValue(b.name, this.audienceLangCode);
                if (aName && bName) {
                    if (aName.toLowerCase() > bName.toLowerCase()) {
                        return 1;
                    }
                    if (aName.toLowerCase() < bName.toLowerCase()) {
                        return -1;
                    }
                }
                return 0;
            });
        } else if (this.selState.activeSorting === SortingType.NAME_ZA) {
            return p_contents.sort((a: ContentModel, b: ContentModel) => {
                const aName: string = languageManager.getTranslationForValue(a.name, this.audienceLangCode);
                const bName: string = languageManager.getTranslationForValue(b.name, this.audienceLangCode);
                if (aName && bName) {
                    if (aName.toLowerCase() > bName.toLowerCase()) {
                        return -1;
                    }
                    if (aName.toLowerCase() < bName.toLowerCase()) {
                        return 1;
                    }
                }
                return 0;
            });
        }
        return p_contents;
    }

    private async _onContentClick(p_content: ContentModel, p_e: Event) {
        if (p_content.loadingStatus === LoadingStatus.BODY_LOADED) {
            this.selState.selected = p_content;
        }
    }

    private _onContentLikeChanged(p_content: ContentModel) {
    }

    private async _onContentFolderClick(p_contentFolder: ContentFolderModel, p_e: Event) {
        this.contentFolderListModel.activeContentFolder = p_contentFolder;
        // scroll to top
        await Vue.nextTick();
        const el: any = this.$el.querySelector('#home-content-overview');
        if (el) {
            el.scrollTop = 0;
        }
    }

    private async _onContentFolderClickDots(p_contentFolder: ContentFolderModel, p_e: Event) {
        // this.selState.selected = p_contentFolder;
    }

    // ADD CONTENT BOX

    private async _onAddPresentationBtnClick(p_e: Event) {
        this.createContentType = EntityType.PRESENTATION;
        this.createContentTitle = "Create a presentation";
        this.$bvModal.show('create-content-box');
    }

    private async _onAddFileBtnClick(p_e: Event) {
        this.createContentType = EntityType.CONTENT_FILE;
        this.createContentTitle = this.$t("AddAFile") as string;
        this.$bvModal.show('create-content-box');
    }

    private async _onAddAppBtnClick(p_e: Event) {
        this.createContentType = EntityType.CONTENT_APP;
        this.createContentTitle = this.$t("AddAnApp") as string;
        this.$bvModal.show('create-content-box');
    }

    private async _onAddFolderBtnClick(p_e: Event) {
        const newFolder: ContentFolderModel = new ContentFolderModel(ContentFolderListModel.getInstance());
        newFolder.body.name[AppUserModel.getInstance().langCode] = this.$t("NewFolder") as string;
        newFolder.body.poster[AppUserModel.getInstance().langCode] = `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/presentation_poster_default.png`; //todo default poster image
        newFolder.viewableTeams = [];
        EventBus.$emit(EventBusActions.ASK_CREATE_EDIT_CONTENT_FOLDER, newFolder);
    }

    private _onCreateContentFinish(p_createdContent: ContentModel) {
        this.$bvModal.hide('create-content-box');
        if (p_createdContent) {
            EventBus.$emit(EventBusActions.ASK_EDIT_ENTITY, p_createdContent);
        }
    }

    // SELECT WATCH
    @Watch('selState.id', {immediate: true, deep: false})
    private async _onSelStateSelectedChange(newSelected: any | null, oldSelected: any | null) {
        if (newSelected) {
            await Vue.nextTick();
            const el: any = this.$el.querySelector(`#${this.selState.id}`);
            if (el) {
                el.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }
    }

    private _onSearchInput(p_e: Event | null) {
        this.contentFolderListModel.inSearchMode = this.selState.searchFilter.length > 1;
    }

    private _togglePlayListOverview() {
        this.appModel.togglePlaylistOverviewOpen();
    }

}
</script>
