<template>
  <div class="edit-content-file-box">

    <transition name="fade" v-if="currentFormState === formState.EDIT">
      <div :key="playlist.playlistID">

        <h4 class="mb-3">{{ playlistName }}</h4>


        <div class="aa-container">
          <div class="row">

            <div class="col-4">
              <AAImage class="mb-3" :imageUrl="posterUrl"
                       v-on:image-loaded="previewLoaded = true"></AAImage>
              <button v-if="playlist.fileType !== fileType.IMAGE" class="btn btn-primary btn-block"
                      @click="_onChoosePosterBtnClick">
                {{ $t('ChoosePoster') }}
              </button>
            </div>

            <div class="col-8">

              <transition name="fade">
                <div :key="playlist.playlistID">

                  <b-form-group>
                    <b-form-input v-model="validationPlaylistName"
                                  :placeholder="$t('Name')"
                                  :state="_calculateInputState($v.validationPlaylistName)"
                                  @input="playlistName = _onFormValidationInput($v.validationPlaylistName, playlistName)"
                                  tabindex="1"></b-form-input>
                    <b-form-invalid-feedback>
                      <span class="d-block" v-if="!$v.validationPlaylistName.required">{{
                          $t('FieldRequiredSimple')
                        }}</span>
                      <span class="d-block" v-if="!$v.validationPlaylistName.minLength">{{
                          $t('MinimumFieldLengthSimple', [3])
                        }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>



                  <b-form-textarea v-model="playlistNotes"
                                   :placeholder="$t('Notes')"
                                   @input="_onFormInput"
                                   rows="5"
                                   tabindex="2"></b-form-textarea>

                  <div class="d-flex justify-content-end mt-4" v-if="isNewPlaylist">
                    <!--                                        <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="3">-->
                    <!--                                            {{ $t('Cancel') }}-->
                    <!--                                        </b-button>-->
                    <b-button type="submit" variant="primary" @click="_onCreateBtnClick"
                              :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid"
                              tabindex="4">{{
                        $t('CreatePlaylist')
                      }}
                    </b-button>
                  </div>

                </div>
              </transition>

            </div>

          </div>
        </div>

      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATING_REMOTE" class="text-center">
        <div v-if="currentFormState === formState.CREATING_REMOTE" class="text-center">
          <b-spinner variant="dark" class="my-5"></b-spinner>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="mb-4 text-danger">{{ $t('PlaylistCreationFailed') }}</p>
        <div class="d-flex justify-content-end">
          <!--                    <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>-->
          <button class="btn btn-primary" @click="_onCreateBtnClick">{{ $t('Retry') }}</button>
        </div>
      </div>
    </transition>

    <b-modal id="poster-picker-box" size="xl" :title="$t('PickPosterOrUploadNewOne')" hide-footer>
      <AssetFoldersPicker :selectedFileUri="posterUrl"
                          :allowedTargetType="posterAllowedTargetType"
                          :allowedFileTypes="avatarAllowedFileTypes"
                          v-on:onAssetFilePicked="_onPosterFilePicked"></AssetFoldersPicker>
    </b-modal>


  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {EntityType, UploadStatus} from "@/entity/_model/entity.constants";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import fileManager from "@/_controller/FileManager";
import {minLength, required} from "vuelidate/lib/validators";
import AAImage from "@/_view/components/AAImage.vue";
import PlaylistModel from "@/playlist/_model/PlaylistModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import MorePopup from "@/_view/components/MorePopup.vue";
import playlistController from "@/playlist/_controller/PlaylistController";
import {ITeamOutlineDto} from "@/team/_model/team.dto";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import NetworkManager from "@/_controller/NetworkManager";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";

enum FormState {
    EDIT,
    TRY_SUBMIT,
    CREATING_REMOTE,
    REMOTE_CREATED,
    REMOTE_ERROR
}

enum CopyToClipboardState {
    IDLE,
    SUCCESS,
    ERROR
}

@Component({
    components: {
        AAImage,
        AssetFoldersPicker,
        MorePopup,
        EntityActionBox,
    },
    validations: {
        validationPlaylistName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class CreateEditPlaylistBox extends Vue {

    @Prop() private playlist!: PlaylistModel;

    private isNewPlaylist: boolean = false;
    private validationPlaylistName: string = '';
    private uploadStatus: typeof UploadStatus = UploadStatus;
    private fileType: typeof FileType = FileType;
    private previewLoaded: boolean = false;
    public posterAllowedTargetType: FileTargetType = FileTargetType.PRESENTATION_POSTER;
    public avatarAllowedFileTypes: FileType[] = [FileType.IMAGE];
    private formState: typeof FormState = FormState;
    private currentFormState: FormState = FormState.EDIT;
    public networkManager: NetworkManager = NetworkManager.getInstance();

    get playlistName() {
        return languageManager.getTranslationForValue<string>(this.playlist.body.name, AppUserModel.getInstance().langCode)
    }

    set playlistName(value: string) {
        this.playlist.body.name[AppUserModel.getInstance().langCode] = value;
    }



    get playlistNotes() {
        return languageManager.getTranslationForValue<string>(this.playlist.body.notes, AppUserModel.getInstance().langCode)
    }

    set playlistNotes(value: string) {
        this.playlist.body.notes[AppUserModel.getInstance().langCode] = value;
    }


    get posterUrl() {
        const posterUri: string = languageManager.getTranslationForValue(
          this.playlist.body.poster,
          AppUserModel.getInstance().langCode
        );
        return fileManager.getFileUrl(posterUri);
    }

    get online() {
        return this.networkManager.online;
    }

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get multipleAudiencesSelected(): boolean {
        const globalSelState: EntitySelectionState<AudienceModel> = AudienceListModel.getInstance().globalSelState;
        return globalSelState.hasMultipleSelected && globalSelState.selecteds.length > 0;
    }

    get showShareWithAudience(): boolean {
        const showShareWithAudience: boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.SHARE_ENTITIES_WITH_AUDIENCES.identifier) >= 0;
        return showShareWithAudience;
    }


    get showShareWithMultipleAudiences(): boolean {
        const globalSelState: EntitySelectionState<AudienceModel> = AudienceListModel.getInstance().globalSelState;
        const shareWithMultiple: boolean = globalSelState.hasMultipleSelected && globalSelState.selecteds.length > 0;
        return this.showShareWithAudience && shareWithMultiple;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public async mounted() {
        if (this.playlist.playlistID === 0) {
            this.isNewPlaylist = true;
        }
        this.validationPlaylistName = this.playlistName;
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return inputValidator.$invalid ? false : null;
    }

    private _onFormValidationInput(inputValidator: any, value: any): void {
        if (!inputValidator.$invalid && this.playlistName) {
            value = inputValidator.$model;
            this._onFormInput(null);
        }
        return value;
    }

    private _onFormInput(p_e: Event | null) {
        if (this.playlist) {
            this.playlist.hasChanges = true;
        }
    }


    private async _onChoosePosterBtnClick(p_e: Event) {
        this.$bvModal.show('poster-picker-box');
    }


    private _onPosterFilePicked(p_assetFile: AssetFileModel) {
        this.$bvModal.hide('poster-picker-box');
        if (p_assetFile && this.playlist) {
            this.playlist.body.poster[AppUserModel.getInstance().langCode] = p_assetFile.fileURI;
            this.playlist.hasChanges = true;
        }
    }

    private async _onCreateBtnClick() {
        this.currentFormState = FormState.TRY_SUBMIT;
        if (!this.$v.$invalid) {
            this.currentFormState = FormState.CREATING_REMOTE;
            const createSucceeded: boolean = await playlistController.createPlaylist(this.playlist);
            if (createSucceeded) {
                this.$emit('onFinish');
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
            }
        }
    }

}
</script>
