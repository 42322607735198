<template>
  <div class="sub-page team-teams">

    <!--List overview of all teams-->

    <div class="box box--full-height list-overview team-teams-overview">

      <div class="list-overview-filters">
        <b-input-group>
          <template #prepend>
            <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
          </template>
          <b-form-input v-model="selState.searchFilter"
                        :placeholder="$t('SearchTeams')"></b-form-input>
        </b-input-group>
        <div class="filter-button" @click="_orderTeamsAlphabetClick"
             :class="{ available: true, active: orderedAlphabet }" v-b-tooltip.hover.top
             :title="$t('ToolTipOrderAlphabet')">
          <i class="fas fa-sort-alpha-down fa-lg"></i>
        </div>
      </div>

      <b-button v-if="showCreateTeam" variant="primary" block class="list-overview-button"
                @click="_onCreateTeamBtnClick" :id="hintIds.TEAMS_CREATE_TEAM">
        {{ $t('AddTeam') }}
      </b-button>

      <div class="list-overview-items">

        <transition name="fade">
          <div v-if="currentState === teamsViewState.LOADING"
               class="empty-loader">
            <b-spinner variant="dark"></b-spinner>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="currentState === teamsViewState.LOADED">
            <TeamItemRenderer
                v-for="team in filteredTeams"
                :team="team"
                :key="team.teamID"
                v-on:onClick="_onTeamClick"
                :selState="selState"
            />
          </div>
        </transition>

      </div>

      <b-modal id="create-team-box" :title="$t('AddTeam')" hide-footer>
        <CreateTeamBox v-on:onFinish="_onCreateTeamFinish"></CreateTeamBox>
      </b-modal>

    </div>

    <!--Detail view of a team-->

    <TeamDetail :selState="selState"></TeamDetail>

    <b-popover custom-class="hint-popover" :target="hintIds.TEAMS_CREATE_TEAM"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.TEAMS_CREATE_TEAM"></Hint>
    </b-popover>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CreateTeamBox from "@/team/_view/CreateTeamBox.vue";
import teamController from "@/team/_controller/TeamController";
import TeamModel from "@/team/_model/TeamModel";
import SelectionState from "@/__libs/_model/SelectionState";
import TeamListModel from "@/team/_model/TeamListModel";
import TeamItemRenderer from "@/team/_view/TeamItemRenderer.vue";
import TeamDetail from "@/team/_view/TeamDetail.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import HintListController from "@/help/hint/_controller/HintListController";

enum TeamsViewState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED,
    NO_USERS
}

@Component({
    components: {Hint, TeamDetail, CreateTeamBox, TeamItemRenderer}
})
export default class TeamsView extends Vue {

    private hintIds: typeof HintIds = HintIds;

    private teamsViewState: typeof TeamsViewState = TeamsViewState;
    private currentState: TeamsViewState = TeamsViewState.IDLE;

    private teamListModel: TeamListModel = TeamListModel.getInstance();

    public selState: SelectionState<TeamModel> = TeamListModel.getInstance().globalSelState;

    private teams: TeamModel[] = this.teamListModel.list;

    private orderedAlphabet: boolean = false;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get filteredTeams() {
        if (this.selState.searchFilter.length > 1) {
            return this.teams.filter(team => {
                return team.displayName.toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1
            });
        } else {
            return this.teams;
        }
    }

    get activeTeam(): TeamModel | null {
        return this.selState.selected;
    }

    get showCreateTeam(): boolean {
        // console.log(AppUserModel.getInstance().project.tierConfig.maxNrOfTeams, this.teams.length);
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.CREATE_TEAMS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.maxNrOfTeams > this.teams.length;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public async mounted() {
        this.currentState = TeamsViewState.LOADING;
        await teamController.fetchAllTeams();
        this.currentState = TeamsViewState.LOADED;
        //
        this._orderTeamsCreatedDate();
        //
        HintListController.startGroup(HintGroupIds.TEAMS);
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private async _onTeamClick(p_team: TeamModel, p_e: Event) {
        this.selState.selected = p_team;


    }

    private _onCreateTeamBtnClick(p_e: Event) {
        this.$bvModal.show('create-team-box');
    }

    private async _onCreateTeamFinish(p_createdTeam: TeamModel) {
        this.$bvModal.hide('create-team-box');
        if (p_createdTeam) {
            this.selState.selected = p_createdTeam;


            // order
            if (this.orderedAlphabet) {
                this._orderTeamsAlphabet();
            } else {
                this._orderTeamsCreatedDate();
            }
        }
    }

    // ordering

    private _orderTeamsAlphabetClick() {
        if (this.orderedAlphabet) {
            this._orderTeamsCreatedDate();
            this.orderedAlphabet = false;
        } else {
            this._orderTeamsAlphabet();
            this.orderedAlphabet = true;
        }
    }

    private _orderTeamsAlphabet() {
        this.teams.sort((a: TeamModel, b: TeamModel) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    private _orderTeamsCreatedDate() {
        this.teams.sort((a: TeamModel, b: TeamModel) => {
            let dateA: Date | null = null;
            if (a.createdDate instanceof Date) {
                dateA = a.createdDate;
            } else {
                dateA = new Date(a.createdDate);
            }
            let dateB: Date | null = null;
            if (b.createdDate instanceof Date) {
                dateB = b.createdDate;
            } else {
                dateB = new Date(b.createdDate);
            }
            if (dateA > dateB) {
                return -1;
            }
            if (dateA < dateB) {
                return 1;
            }
            return 0;
        });
    }


}
</script>
