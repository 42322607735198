<template>
  <div class="box funnel-stage-item">

    <div class="funnel-stage-item__details">
      <h5 class="mb-2">{{ funnelStageName }}</h5>
      <p class="text-secondary small mb-2" v-html="funnelStageDesc" v-if="funnelStageDesc"></p>
      <AudienceItemRenderer
          v-if="activeAudience"
          :class="{ active: isAssigned, disabled: !online }"
          :audience="activeAudience"
          :key="activeAudience.ID"
          v-on:onClick="_onAudienceClick"
          :item-id="`funnel-${funnelStage.reference}-audience-${activeAudience.ID}`" />

    </div>

    <div class="funnel-stage-item__list">
      <ContentOverviewItemRenderer
          v-for="content in shownContent"
          :content="content"
          :key="content.ID"
          v-on:onClick="_onContentClick"
          :selState="selState"
          :ref="content.ID"
          :inFunnel="true"
          :parentReference="funnelStage.reference"
      />
    </div>


<!--        <div v-if="activeAudience" class="funnel-stage-item__audience d-flex align-items-center"-->
<!--             :class="{ active: isAssigned, 'body-loaded': true }" >-->
<!--          <div class="funnel-stage-item__audience__profile">-->
<!--            <AAProfilePicture :name="activeAudience.displayName"-->
<!--                              :imageUri="activeAudience.avatarFileUri"></AAProfilePicture>-->
<!--          </div>-->
<!--          <div class="funnel-stage-item__audience__info flex-grow-1">-->
<!--            <h6 class="mb-0" v-line-clamp="1">{{ activeAudience.displayName }}</h6>-->
<!--          </div>-->
<!--        </div>-->


  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ContentModel from "@/content/_model/ContentModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ContentListModel from "@/content/_model/ContentListModel";
import ContentOverviewItemRenderer from "@/content/_view/ContentOverviewItemRenderer.vue";
import {SortingType} from "@/_model/app.constants";
import CompanyModel from "@/company/_model/CompanyModel";
import CompanyListModel from "@/company/_model/CompanyListModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import audienceController from "@/audience/_controller/AudienceController";
import {IProjectFunnelStageDto} from "@/project/_model/project.dto";
import AudienceItemRenderer from "@/audience/_view/AudienceItemRenderer.vue";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {
    AAProfilePicture,
    ContentOverviewItemRenderer,
    AudienceItemRenderer
  }
})
export default class FunnelStageItemRenderer extends Vue {

  public networkManager: NetworkManager = NetworkManager.getInstance();

  @Prop() public funnelStage!: IProjectFunnelStageDto;
  @Prop() private selState!: EntitySelectionState<ContentModel>;

  private contents: ContentModel[] = ContentListModel.getInstance().list;

  get online() {
    return this.networkManager.online;
  }

  get funnelStageName(): string {
    return languageManager.getTranslationForValue<string>(this.funnelStage.name!, AppUserModel.getInstance().langCode);
  }

  get funnelStageDesc(): string {
    return languageManager.getTranslationForValue<string>(this.funnelStage.description!, AppUserModel.getInstance().langCode);
  }

  get activeAudience(): AudienceModel | null {
    return AudienceListModel.getInstance()
        .globalSelState.selected;
  }

  get audienceLangCode(): string | null {
    return this.activeAudience ? this.activeAudience.langCode : null;
  }

  get companyName() {
    if (this.activeAudience) {
      const company: CompanyModel | null = CompanyListModel.getInstance().getEntityByID(this.activeAudience.companyID);
      return company ? company.displayName : "";
    }
    return null;
  }

  get shownContent() {
    const shownContent = this.contents.filter(content => {
      return content.funnelStages.indexOf(this.funnelStage.reference!) >= 0;
    });
    return this._sortContent(shownContent)
  }

  get isAssigned() {
    if (this.activeAudience) {
      return this.activeAudience.funnelStage === this.funnelStage.reference;
    }
    return false;
  }

  private async _onContentClick(p_content: ContentModel, p_e: Event) {
    if (p_content.loadingStatus === LoadingStatus.BODY_LOADED) {
      this.selState.selected = p_content;
    }
  }

  public _onAudienceClick(p_e: Event) {
    if (this.activeAudience) {
      this.activeAudience.funnelStage = this.funnelStage.reference!;
      audienceController.saveBody(this.activeAudience);
    }
  }

  private _sortContent(p_contents: ContentModel[]) {
    if (this.selState.activeSorting === SortingType.NEWEST_FIRST) {
      return p_contents.sort((a: ContentModel, b: ContentModel) => {
        if (a.createdDate > b.createdDate) {
          return -1;
        }
        if (a.createdDate < b.createdDate) {
          return 1;
        }
        return 0;
      });
    } else if (this.selState.activeSorting === SortingType.OLDEST_FIRST) {
      return p_contents.sort((a: ContentModel, b: ContentModel) => {
        if (b.createdDate > a.createdDate) {
          return -1;
        }
        if (b.createdDate < a.createdDate) {
          return 1;
        }
        return 0;
      });
    } else if (this.selState.activeSorting === SortingType.NAME_AZ) {
      return p_contents.sort((a: ContentModel, b: ContentModel) => {
        const aName: string = languageManager.getTranslationForValue(a.name, this.audienceLangCode);
        const bName: string = languageManager.getTranslationForValue(b.name, this.audienceLangCode);
        if (aName && bName) {
          if (aName.toLowerCase() > bName.toLowerCase()) {
            return 1;
          }
          if (aName.toLowerCase() < bName.toLowerCase()) {
            return -1;
          }
        }
        return 0;
      });
    } else if (this.selState.activeSorting === SortingType.NAME_ZA) {
      return p_contents.sort((a: ContentModel, b: ContentModel) => {
        const aName: string = languageManager.getTranslationForValue(a.name, this.audienceLangCode);
        const bName: string = languageManager.getTranslationForValue(b.name, this.audienceLangCode);
        if (aName && bName) {
          if (aName.toLowerCase() > bName.toLowerCase()) {
            return -1;
          }
          if (aName.toLowerCase() < bName.toLowerCase()) {
            return 1;
          }
        }
        return 0;
      });
    }
    return p_contents;
  }

}
</script>

