<template>
  <div class="audience-overview">

    <div class="box box--full-height list-overview">

      <h5 class="title mt-1 mb-3" v-html="$t('AudienceOverviewTitle')"></h5>

      <div class="list-overview-filters">

        <b-input-group>
          <template #prepend>
            <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
          </template>
          <b-form-input v-model="selState.searchFilter"
                        :placeholder="$t('AudienceFilterBarSearchPlaceholder')"></b-form-input>
        </b-input-group>

        <div class="list-overview-filters__icons">
          <div class="filter-button-group">
            <div class="filter-button" @click="toggleMultiSelect"
                 :class="{ available: fullListBodyLoaded, active: selState.isMultiSelect }" v-b-tooltip.hover.top
                 :title="$t('ToolTipSelectMultiple').toString()">
              <i class="fas fa-user-friends fa-lg"></i>
            </div>

            <div class="filter-button" @click="toggleCompany"
                 :class="{ available: fullListBodyLoaded, active: isGroupedByCompany }" v-b-tooltip.hover.top
                 :title="$t('ToolTipCompanyView').toString()">
              <i class="fas fa-building fa-lg"></i>
            </div>
          </div>

          <div class="filter-button-group">
            <div class="filter-button not-available" @click="orderAudiencesAlphabetClick"
                 :class="{ available: fullListBodyLoaded, active: orderedAlphabet }" v-b-tooltip.hover.top
                 :title="$t('ToolTipOrderAlphabet').toString()">
              <i class="fas fa-sort-alpha-down fa-lg"></i>
            </div>

            <div v-show="hasCustomAudienceFilterProps" tabindex="1" class="filter-button not-available"
                 id="popover-audience-props"
                 :class="{available: fullListBodyLoaded, active: hasActiveAudienceFilters}"
                 v-b-tooltip.hover.top :title="$t('ToolTipAudienceProperties').toString()">
              <i class="fas fa-filter fa-lg"></i>
            </div>
          </div>
        </div>

        <b-popover
            target="popover-audience-props"
            triggers="click blur"
            placement="right"
            custom-class="popover-audience-props p-2"
            boundary-padding="44"
            boundary="window"
        >
          <transition name="fade">
            <DataFilter
                v-if="audiencesFilterState === LoadingState.LOADED"
                :filter-model="audiencesFilterModel"
                @apply-filters="onApplyFilters"
                @reset-filters="onResetFilters"
            />

            <div v-else-if="audiencesFilterState === LoadingState.ERROR_LOADING"
                 class="placeholder-text text-danger d-flex justify-content-center align-items-center w-100 h-100">
              {{ $t('ErrorConnectionRequired') }}
            </div>

            <div v-else-if="audiencesFilterState === LoadingState.LOADING"
                 class="loading d-flex justify-content-center align-items-center w-100 h-100">
              <b-spinner variant="dark"/>
            </div>
          </transition>
        </b-popover>

      </div>

      <div class="mb-3">
        <b-button block variant="primary" @click="onCreateAudienceBtnClick"
                  :id="hintIds.HOME_CREATE_AUDIENCE" v-html="$t('DashboardMasterAddAudienceButton')"></b-button>
        <b-button v-if="showImportAudiencesBtn" block variant="primary"
                  @click="_onImportAudiencesBtnClick" v-html="$t('ImportAudiences')"></b-button>
      </div>

      <transition name="fade">
        <div class="list-overview-items" v-if="isGroupedByCompany">
          <CompanyGroupItemRenderer
              v-for="company in filteredCompanies"
              :company="company"
              :key="company.ID"
              :selState="selState"
              :audiences-filter-model="audiencesFilterModel" />
        </div>
      </transition>

      <transition name="fade">
        <div class="list-overview-items" v-if="!isGroupedByCompany">
          <AudienceItemRenderer
              v-for="audience in filteredAudiences"
              :audience="audience"
              :key="audience.ID"
              v-on:onClick="_onAudienceClick"
              :selState="selState"
              :item-id="`audience-${audience.ID}`"/>
        </div>
      </transition>

    </div>

    <div class="audience-overview__toggle" @click="toggle">
      <i class="fas fa-chevron-left" v-if="audienceOverviewOpen"></i>
      <i class="fas fa-chevron-right" v-if="!audienceOverviewOpen"></i>
    </div>

    <b-modal id="create-audience-box" :title="$t('DashboardMasterAddAudienceButton')" hide-footer>
      <CreateAudienceBox v-on:onFinish="_onCreateAudienceFinish"></CreateAudienceBox>
    </b-modal>

    <b-modal v-if="showImportAudiencesBtn" id="import-audiences-box" :title="$t('ImportAudiences')" hide-footer
             size="lg">
      <ImportAudiencesBox v-on:onFinish="_ImportAudiencesFinish"></ImportAudiencesBox>
    </b-modal>

    <b-popover custom-class="hint-popover" :target="hintIds.HOME_CREATE_AUDIENCE"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.HOME_CREATE_AUDIENCE"></Hint>
    </b-popover>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AudienceItemRenderer from "@/audience/_view/AudienceItemRenderer.vue";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import audienceListController from "@/audience/_controller/AudienceListController";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import {LocalStorageKey} from "@/_model/app.constants";
import CreateAudienceBox from "@/audience/_view/CreateAudienceBox.vue";
import {RoutingIdentifier} from "@/router";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import companyListController from "@/company/_controller/CompanyListController";
import CompanyListModel from "@/company/_model/CompanyListModel";
import CompanyModel from "@/company/_model/CompanyModel";
import CompanyGroupItemRenderer from "@/audience/_view/CompanyGroupItemRenderer.vue";
import JsonUtil from "@/__libs/utility/JsonUtil";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import ImportAudiencesBox from "@/audience/_view/ImportAudiencesBox.vue";
import AppModel from "@/_model/AppModel";
import DataFilter from '@/data_tool/data_filter/_view/DataFilter.vue';
import DataProviderEditor from '@/data_tool/data_provider/_view/DataProviderEditor.vue';
import {IDataFilter} from '@/data_tool/data_filter/_model/data-filter.dto';
import DataUtil from '@/data_tool/_controller/DataUtil';
import {FormElement} from '@/data_tool/_model/data_tool.constants';
import DataFilterModel from '@/data_tool/data_filter/_model/DataFilterModel';
import DataFilterController from '@/data_tool/data_filter/_controller/DataFilterController';
import CrmModel from "@/crm/_model/CrmModel";

enum LoadingState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED
}

@Component({
    components: {
        DataProviderEditor,
        DataFilter,
        Hint,
        AudienceItemRenderer,
        CreateAudienceBox,
        CompanyGroupItemRenderer,
        ImportAudiencesBox
    },
})
export default class AudiencesOverview extends Vue {

    @Prop() public collapsed!: boolean;
    public appModel: AppModel = AppModel.getInstance();

    public readonly LoadingState: typeof LoadingState = LoadingState;
    public audiencesFilterState: LoadingState = LoadingState.IDLE;

    public readonly audiencesFilterModel: DataFilterModel = new DataFilterModel();

    public hintIds: typeof HintIds = HintIds;
    private audienceListModel: AudienceListModel = AudienceListModel.getInstance();
    public selState: EntitySelectionState<AudienceModel> = AudienceListModel.getInstance().globalSelState;
    private audiences: AudienceModel[] = this.audienceListModel.list;
    private companies: CompanyModel[] = CompanyListModel.getInstance().list;
    public orderedAlphabet: boolean = false;
    public isGroupedByCompany: boolean = false;

    get audienceOverviewOpen() {
        return this.appModel.audienceOverviewOpen;
    }

    get filteredAudiences() {
        let audiences: AudienceModel[] = this.audiences;

        if (this.selState.searchFilter.length > 1) {
            audiences = this.audiences.filter(audience => {
                return audience.displayName.toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1 || this.getCompanyName(audience).toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1
            });
        }

        const appliedFilters = this.audiencesFilterModel.appliedFilters.filter(fv => fv.value);
        if (appliedFilters.length > 0) {
            audiences = audienceListController.filterByCustomProps(audiences, appliedFilters, this.audiencesFilterModel);
        }

        return audiences;
    }

    get filteredCompanies() {
        // if (this.selState.searchFilter.length > 1)
        // {
        //     return this.companies.filter(company => {
        //         return company.displayName.toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1
        //     });
        // }
        // else
        // {
        //     return this.companies;
        // }
        return this.companies;
    }

    get fullListBodyLoaded(): boolean {
        return this.audienceListModel.fullListBodyLoaded;
    }

    get showImportAudiencesBtn(): boolean {
        return CrmModel.getInstance().getAvailableCrmProvidersForUser().length > 0;
    }

    get hasCustomAudienceFilterProps(): boolean {
      return this.audiencesFilterModel.propertyDefinitions.length > 0;
    }

    get hasActiveAudienceFilters(): boolean {
        return this.audiencesFilterModel.appliedFilters.some(fv => fv.value);
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public async mounted() {
        await companyListController.fetchAllEntities();
        audienceListController.fetchAllEntities();

        this.isGroupedByCompany = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.GROUP_BY_COMPANY) as string) ? true : false;

        //
        this._orderAudiencesCreatedDate();
        this._orderCompaniesCreatedDate();
        // on init default select first audience
        if (this.audiences.length > 0 && !this.selState.selected) {
            this.selState.selected = this.audiences[0];
        }

        this.fetchAudienceCustomFilterProperties();
    }

    public async _onAudienceClick(p_audience: AudienceModel, p_e: Event) {
        if (p_audience.loadingStatus === LoadingStatus.BODY_LOADED) {
            this.selState.setSelected(p_audience);
        }
    }

    private getCompanyName(p_audience: AudienceModel) {
        const company: CompanyModel | null = CompanyListModel.getInstance().getEntityByID(p_audience.companyID);
        return company ? company.displayName : "";
    }

    public onCreateAudienceBtnClick(p_e: Event) {
        this.$bvModal.show('create-audience-box');
    }

    private _onCreateAudienceFinish(p_createdAudience: AudienceModel) {
        this.$bvModal.hide('create-audience-box');
        if (p_createdAudience) {
            this.$router.push({name: RoutingIdentifier.AUDIENCE_DETAIL});
            // order
            if (this.orderedAlphabet) {
                this._orderAudiencesAlphabet();
            } else {
                this._orderAudiencesCreatedDate();
            }
        }
    }

    public _onImportAudiencesBtnClick(p_e: Event) {
        this.$bvModal.show('import-audiences-box');
    }

    public _ImportAudiencesFinish() {
        this.$bvModal.hide('import-audiences-box');
    }

    public async fetchAudienceCustomFilterProperties() {
        this.audiencesFilterState = LoadingState.LOADING;
        try {
            await new DataFilterController(this.audiencesFilterModel)
              .initModel('audience_schema', 'custom_crm_props');
            this.audiencesFilterState = LoadingState.LOADED;
        } catch (e) {
            this.audiencesFilterState = LoadingState.ERROR_LOADING;
        }
    }

    public onApplyFilters() {
        this.audiencesFilterModel.appliedFilters = DataUtil.deepCopy(this.audiencesFilterModel.filterValues);
    }

    public onResetFilters() {
        this.audiencesFilterModel.reset();
    }

    // ordering
    public orderAudiencesAlphabetClick() {
        if (this.orderedAlphabet) {
            this._orderAudiencesCreatedDate();
            this._orderCompaniesCreatedDate();
            this.orderedAlphabet = false;
        } else {
            this._orderAudiencesAlphabet();
            this._orderCompaniesAlphabet();
            this.orderedAlphabet = true;
        }
    }

    private _orderAudiencesAlphabet() {
        this.audiences.sort((a: AudienceModel, b: AudienceModel) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    private _orderAudiencesCreatedDate() {
        this.audiences.sort((a: AudienceModel, b: AudienceModel) => {
            let dateA: Date | null = null;
            if (a.createdDate instanceof Date) {
                dateA = a.createdDate;
            } else {
                dateA = new Date(a.createdDate);
            }
            let dateB: Date | null = null;
            if (b.createdDate instanceof Date) {
                dateB = b.createdDate;
            } else {
                dateB = new Date(b.createdDate);
            }
            if (dateA > dateB) {
                return -1;
            }
            if (dateA < dateB) {
                return 1;
            }
            return 0;
        });
    }

    private _orderCompaniesAlphabet() {
        this.companies.sort((a: CompanyModel, b: CompanyModel) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    private _orderCompaniesCreatedDate() {
        this.companies.sort((a: CompanyModel, b: CompanyModel) => {
            let dateA: Date | null = null;
            if (a.createdDate instanceof Date) {
                dateA = a.createdDate;
            } else {
                dateA = new Date(a.createdDate);
            }
            let dateB: Date | null = null;
            if (b.createdDate instanceof Date) {
                dateB = b.createdDate;
            } else {
                dateB = new Date(b.createdDate);
            }
            if (dateA > dateB) {
                return -1;
            }
            if (dateA < dateB) {
                return 1;
            }
            return 0;
        });
    }

    public toggleMultiSelect() {
        this.selState.isMultiSelect = !this.selState.isMultiSelect;
    }

    public toggleCompany() {
        this.isGroupedByCompany = !this.isGroupedByCompany;
        LocalStorageManager.storeValue(LocalStorageKey.GROUP_BY_COMPANY, JsonUtil.stringify(this.isGroupedByCompany));
    }

    public toggle() {
        this.appModel.toggleAudienceOverviewOpen();
    }

}
</script>
