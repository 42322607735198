<template>
    <div class="slides">
      <SlidesOverview :selState="selState"></SlidesOverview>
      <SlideDetail :selState="selState"></SlideDetail>
    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";
    import SlidesOverview from "@/slide/_view/SlidesOverview.vue";
    import SlideModel from "@/slide/_model/SlideModel";
    import SlideSelectionState from "@/slide/_model/SlideSelectionState";
    import SlideDetail from "@/slide/_view/SlideDetail.vue";
    import AssetFileDetail from "@/slide/_view/AssetFileDetail.vue";
    import HintListController from "@/help/hint/_controller/HintListController";
    import {HintGroupIds} from "@/help/hint/_model/hint.constants";

    @Component({
        components: {SlidesOverview, SlideDetail}
    })
    export default class SlidesView extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private selState!:SlideSelectionState;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted() {
            HintListController.startGroup(HintGroupIds.SLIDES1);
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------
    }
</script>
