<template>
  <div class="sub-page team-companies">

    <!--List overview of all companies-->

    <div class="box box--full-height list-overview team-companies-overview">

      <div class="list-overview-filters">
        <b-input-group>
          <template #prepend>
            <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
          </template>
          <b-form-input v-model="selState.searchFilter"
                        :placeholder="$t('SearchCompanies')"></b-form-input>
        </b-input-group>
        <div class="filter-button" @click="_orderCompaniesAlphabetClick"
             :class="{ available: true, active: orderedAlphabet }" v-b-tooltip.hover.top
             :title="$t('ToolTipOrderAlphabet')">
          <i class="fas fa-sort-alpha-down fa-lg"></i>
        </div>
      </div>

      <b-button v-if="showCreateCompany" variant="primary" block class="list-overview-button"
                @click="_onCreateCompanyBtnClick">
        {{ $t('AddCompany') }}
      </b-button>

      <div class="list-overview-items" :id="hintIds.COMPANIES_OVERVIEW_COMPANIES">

        <CompanyItemRenderer
            v-for="company in filteredCompanies"
            :company="company"
            :key="company.companyID"
            v-on:onClick="_onCompanyClick"
            :selState="selState"
        />

      </div>

      <b-modal id="create-company-box" :title="$t('AddCompany')" hide-footer>
        <CreateCompanyBox v-on:onFinish="_onCreateCompanyFinish"></CreateCompanyBox>
      </b-modal>

    </div>

    <CompanyDetail :selState="selState"></CompanyDetail>

    <b-popover custom-class="hint-popover" :target="hintIds.COMPANIES_OVERVIEW_COMPANIES"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.COMPANIES_OVERVIEW_COMPANIES"></Hint>
    </b-popover>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CreateCompanyBox from "@/company/_view/CreateCompanyBox.vue";
import companyController from "@/company/_controller/CompanyController";
import CompanyModel from "@/company/_model/CompanyModel";
import SelectionState from "@/__libs/_model/SelectionState";
import CompanyListModel from "@/company/_model/CompanyListModel";
import CompanyItemRenderer from "@/company/_view/CompanyItemRenderer.vue";
import CompanyDetail from "@/company/_view/CompanyDetail.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import companyListController from "@/company/_controller/CompanyListController";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import HintListController from "@/help/hint/_controller/HintListController";


enum CompaniesViewState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED,
    NO_USERS
}

@Component({
    components: {Hint, CompanyDetail, CreateCompanyBox, CompanyItemRenderer}
})
export default class CompaniesView extends Vue {

    private hintIds: typeof HintIds = HintIds;

    private companiesViewState: typeof CompaniesViewState = CompaniesViewState;
    private currentState: CompaniesViewState = CompaniesViewState.IDLE;

    private companyListModel: CompanyListModel = CompanyListModel.getInstance();

    public selState: SelectionState<CompanyModel> = CompanyListModel.getInstance().globalSelState;

    private companies: CompanyModel[] = this.companyListModel.list;

    private orderedAlphabet: boolean = false;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get filteredCompanies() {
        if (this.selState.searchFilter.length > 1) {
            return this.companies.filter(company => {
                return company.displayName.toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1
            });
        } else {
            return this.companies;
        }
    }

    get activeCompany(): CompanyModel | null {
        return this.selState.selected;
    }

    get showCreateCompany(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.CREATE_COMPANIES.identifier) >= 0;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public async mounted() {
        companyListController.fetchAllEntities();
        //
        this._orderCompaniesCreatedDate();
        //
        HintListController.startGroup(HintGroupIds.COMPANIES);
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private async _onCompanyClick(p_company: CompanyModel, p_e: Event) {
        this.selState.selected = p_company;


    }

    private _onCreateCompanyBtnClick(p_e: Event) {
        this.$bvModal.show('create-company-box');
    }

    private async _onCreateCompanyFinish(p_createdCompany: CompanyModel) {
        this.$bvModal.hide('create-company-box');
        if (p_createdCompany) {
            this.selState.selected = p_createdCompany;


            // order
            if (this.orderedAlphabet) {
                this._orderCompaniesAlphabet();
            } else {
                this._orderCompaniesCreatedDate();
            }
        }
    }

    // ordering

    private _orderCompaniesAlphabetClick() {
        if (this.orderedAlphabet) {
            this._orderCompaniesCreatedDate();
            this.orderedAlphabet = false;
        } else {
            this._orderCompaniesAlphabet();
            this.orderedAlphabet = true;
        }
    }

    private _orderCompaniesAlphabet() {
        this.companies.sort((a: CompanyModel, b: CompanyModel) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    private _orderCompaniesCreatedDate() {
        this.companies.sort((a: CompanyModel, b: CompanyModel) => {
            let dateA: Date | null = null;
            if (a.createdDate instanceof Date) {
                dateA = a.createdDate;
            } else {
                dateA = new Date(a.createdDate);
            }
            let dateB: Date | null = null;
            if (b.createdDate instanceof Date) {
                dateB = b.createdDate;
            } else {
                dateB = new Date(b.createdDate);
            }
            if (dateA > dateB) {
                return -1;
            }
            if (dateA < dateB) {
                return 1;
            }
            return 0;
        });
    }


}
</script>
