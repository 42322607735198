<template>
  <div class="box box--full-height box--scrollable asset-file-detail">

    <transition name="fade">
      <div v-if="!selState.selectedFile" class="empty-message">
        {{ $t('AssetManagementSelectFile') }}
      </div>
    </transition>

    <transition name="fade">
      <div
          v-if="selState.selectedFile && (assetFile.uploadStatus === uploadStatus.UPLOADING || assetFile.uploadStatus === uploadStatus.QUEUED)"
          class="empty-loader">
        <b-spinner></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="selState.selectedFile && assetFile.uploadStatus === uploadStatus.IDLE" :key="assetFile.fileURI"
           class="box-wrapper">

        <h4 class="mt-1 mb-2">{{ assetFileName }}</h4>
        <div class="mb-4">
          <span class="badge file-type" :class="fileTypeClass">{{ fileTypeLabel }}</span>
        </div>

        <div class="asset-file-detail__preview mb-3" v-if="assetFile.fileType === fileType.VIDEO">
          <video controls :src="assetFileUrl"></video>
        </div>

        <div class="asset-file-detail__preview mb-3" v-if="assetFile.fileType === fileType.IMAGE">
          <AAImage :imageUrl="assetFileUrl"
                   v-on:image-loaded="previewLoaded = true"></AAImage>
        </div>


        <b-form-group>
          <b-form-input v-model="validationAssetFileName"
                        :placeholder="$t('AssetManagementFileName')"
                        :disabled="!isEditable"
                        :state="_calculateInputState($v.validationAssetFileName)"
                        @input="assetFileName = _onFormValidationInput($v.validationAssetFileName, assetFileName)"
                        tabindex="1"></b-form-input>
          <b-form-invalid-feedback>
          <span class="d-block"
                v-if="!$v.validationAssetFileName.required">{{ $t('FieldRequiredSimple') }}</span>
            <span class="d-block"
                  v-if="!$v.validationAssetFileName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-form-textarea v-model="assetFileDesc"
                           :placeholder="$t('AssetManagementFileDescription')"
                           @input="_onFormInput"
                           :disabled="!isEditable"
                           rows="5"
                           tabindex="2"></b-form-textarea>
        </b-form-group>

        <b-button variant="success" block @click="_onOpenFileBtnClick">
          {{ $t('AssetManagementFileOpen') }}
        </b-button>

        <label class="btn btn-outline-secondary btn-block" v-if="isEditable">
          {{ $t('AssetManagementReplaceFile') }} <input type="file" ref="replaceFileBtn"
                                                        v-on:change="_onReplaceFileBtnClick()" hidden>
        </label>

        <b-button v-if="isEditable" variant="outline-secondary" block @click="_onDeleteFileBtnClick">
          {{ $t('DashboardShowDeleteButton') }}
        </b-button>

        <div class="sub-box mt-3">
          <b-form-group>
            <b-form-checkbox v-for="targetType in allowedTargetTypes" :key="targetType" :value="targetType"
                             :disabled="!isEditable" @input="_onFormInput" v-model="assetFile.targetTypes">
              {{ $t(`FileTargetTypes_${targetType}`) }}
            </b-form-checkbox>
          </b-form-group>
        </div>

      </div>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {UploadStatus} from "@/entity/_model/entity.constants";
import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
import {FILE_TYPE_CONFIG, FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import fileManager from "@/_controller/FileManager";
import FileUtil from "@/__libs/utility/FileUtil";
import {RightAction} from "@/team/_model/role.constants";
import {minLength, required} from "vuelidate/lib/validators";
import AAImage from "@/_view/components/AAImage.vue";
import fileSize from "filesize";

@Component({
  components: {
    AAImage
  },
  validations: {
    validationAssetFileName: {
      required,
      minLength: minLength(3)
    }
  }
})
export default class AssetFileDetail extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() private selState!: AssetFolderSelectionState;


  //---------------------------------
  // Vue Component data
  //---------------------------------

  private validationAssetFileName: string = '';

  private uploadStatus: typeof UploadStatus = UploadStatus;

  private fileType: typeof FileType = FileType;

  private previewLoaded: boolean = false;


  //---------------------------------
  // Vue Computed properties
  //---------------------------------

  get assetFileName() {
    return languageManager.getTranslationForValue<string>(this.assetFile.name, AppUserModel.getInstance().langCode)
  }

  set assetFileName(value: string) {
    this.assetFile.name[AppUserModel.getInstance().langCode] = value;
  }

  get assetFileDesc() {
    return languageManager.getTranslationForValue<string>(this.assetFile.description, AppUserModel.getInstance().langCode)
  }

  set assetFileDesc(value: string) {
    this.assetFile.description[AppUserModel.getInstance().langCode] = value;
  }

  get assetFileUrl() {
    return fileManager.getFileUrl(this.assetFile.fileURI);
  }

  get assetPreviewImageUrl() {
    return fileManager.getFileUrl(this.assetFile.previewImageURI);
  }

  get assetFile(): AssetFileModel {
    return this.selState.selectedFile!;
  }

  get assetFolder(): AssetFolderModel {
    return this.selState.selected!;
  }

  get allowedTargetTypes(): FileTargetType[] {
    return FILE_TYPE_CONFIG[this.assetFile.fileType].allowedTargetTypes;
  }

  get inputAccept(): string {
    return FILE_TYPE_CONFIG[this.assetFile.fileType].fileExtensions.join(",");
  }

  get isEditable(): boolean {
    return this.assetFolder.rightActions.indexOf(RightAction.EDIT) >= 0;
  }

  get fileTypeLabel() {
    return this.$t('FileType_' + FILE_TYPE_CONFIG[this.assetFile.fileType].identifier) as string;
  }

  get fileTypeClass() {
    return `aa-file-type--${FILE_TYPE_CONFIG[this.assetFile.fileType].identifier.toLowerCase()}`;
  }

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  public mounted() {
    this._setInputAccept();
  }

  //---------------------------------
  // Private / helper methods
  //---------------------------------

  private _calculateInputState(inputValidator: any): any {
    return inputValidator.$invalid ? false : null;
  }

  private _onFormValidationInput(inputValidator: any, value: any): void {
    if (!inputValidator.$invalid && this.assetFileName) {
      value = inputValidator.$model;
      this._onFormInput(null);
    }
    return value;
  }

  private _onFormInput(p_e: Event | null) {
    if (this.assetFolder) {
      this.assetFolder.hasChanges = true;
    }
  }

  private async _onDeleteFileBtnClick(p_e: Event) {
    const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this file?`, {centered: true});

    if (deleteConfirmed) {
      const isDeleted: boolean = await assetFolderController.deleteFile(this.assetFolder, this.assetFile);
      if (isDeleted) {
        this.selState.selectedFile = null;
      }
    }
  }

  private async _onReplaceFileBtnClick(p_e: Event) {
    const replaceFileBtn: HTMLInputElement = this.$refs.replaceFileBtn as HTMLInputElement;
    if (replaceFileBtn.files && replaceFileBtn.files.length > 0 && this.selState.selected) {
      if (await this.checkValidFileSelect(replaceFileBtn.files[0])) {
        await assetFolderController.replaceFile(this.selState.selected, this.assetFile, replaceFileBtn.files[0]);
      }
    }
  }

  private async checkValidFileSelect(p_file: File): Promise<boolean> {
    const fileType: FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(p_file.name));
    if (fileType === this.assetFile.fileType) {
      if (p_file.size > AppUserModel.getInstance().project.tierConfig.maxFileSize) {
        await this.$bvModal.msgBoxOk(this.$t('ErrorMaxSize', [fileSize(AppUserModel.getInstance().project.tierConfig.maxFileSize)]) as string, {centered: true});
        return false;
      }
      return true;
    } else {
      await this.$bvModal.msgBoxOk(this.$t('ErrorFileType', [this.inputAccept]) as string, {centered: true});
      return false;
    }
  }


  private async _onOpenFileBtnClick(p_e: Event) {
    window.open(this.assetFileUrl, "_blank");
  }

  @Watch('assetFolder', {immediate: true, deep: false})
  private _onActiveAssetFolderChange(newAssetFolder: AssetFolderModel | null, oldAssetFolder: AssetFolderModel | null) {
    if (oldAssetFolder && oldAssetFolder.hasChanges && this.isEditable) {
      assetFolderController.saveBody(oldAssetFolder);
    }
  }

  @Watch('$route', {immediate: true, deep: true})
  private _onRouteChange(newVal: any) {
    if (this.assetFolder && this.assetFolder.hasChanges && this.isEditable) {
      assetFolderController.saveBody(this.assetFolder);
    }
  }

  @Watch('assetFile', {immediate: true, deep: false})
  private _onActiveAssetFileChange() {
    this._setInputAccept();

    if (this.assetFolder && this.assetFolder.hasChanges && this.isEditable) {
      assetFolderController.saveBody(this.assetFolder);
    }

    if (this.assetFile) {
      this.validationAssetFileName = this.assetFileName;
    }

    this.previewLoaded = false;
  }

  //todo: also watch window.beforeunload


  //todo: BUG got broken after styling
  private _setInputAccept() {

    const replaceFileBtn: HTMLInputElement = this.$refs.replaceFileBtn as HTMLInputElement;
    if (this.assetFile && replaceFileBtn) {
      replaceFileBtn.accept = this.inputAccept;
    }
  }

}
</script>
